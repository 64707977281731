/* eslint-disable import/first */

import Vue from 'vue'

// Register composition api before further import calls
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

//
// ─── VENDOR ─────────────────────────────────────────────────────────────────────
//

// JS
import Buefy from 'buefy'
Vue.use(Buefy, { defaultIconPack: 'fas' })

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'
import de from 'vee-validate/dist/locale/de.json'
import IdTag from '@/components/IdTag'

extend('required', required)
extend('email', email)
extend('min', min)
extend('passwordNumber', {
  message: '{_field_} muss mindestens eine Zahl enthalten',
  validate (value, args) {
    const regex = /[0-9]/g
    return regex.test(value)
  }
})
extend('match', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: '{_field_} stimmt nicht überein'
})
localize(de)
localize({
  de: {
    messages: {
      required: '{_field_} ist erforderlich',
      email: 'Ungültige E-Mail Adresse',
      min: '{_field_} muss mindestens {length} Zeichen lang sein'
    }
  }
})
localize('de')
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('IdTag', IdTag)

import dayjs from 'dayjs'
import 'dayjs/locale/de'
dayjs.locale('de')

// CSS
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/brands.css'

//
// ─── APP COMPONENTS ─────────────────────────────────────────────────────────────

// JS
import App from './App.vue'
import router from './router'
import store from './store'

import SignDigitalWebShared from '@custom-media/signdigital-web-shared'

// CSS
import '@/assets/scss/bulma-customized.scss'

//
// ─── ERROR REPORTING AND TRACING ────────────────────────────────────────────────
//
import * as Sentry from '@sentry/vue'

let tracesSampleRate = Number.parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE)
if (tracesSampleRate == null || isNaN(tracesSampleRate)) {
  tracesSampleRate = 0.01
}
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: [
        'localhost',
        /^\//,
        process.env.VUE_APP_API_HOST,
        process.env.VUE_APP_SEARCH_HOST,
        process.env.VUE_APP_CARGO_HOST
      ],
      router
    })
  ],
  enabled: process.env.VUE_APP_ENVIRONMENT !== 'development',
  environment: process.env.VUE_APP_ENVIRONMENT,
  release: process.env.VUE_APP_SENTRY_RELEASE,
  tracesSampleRate
})

//
// ─── SETUP ──────────────────────────────────────────────────────────────────────
//

Vue.use(SignDigitalWebShared, { store })

Vue.config.productionTip = false

// Ensure only not authenticated routes can be accessed
router.beforeEach((to, from, next) => {
  const currentUser = store.getters['auth/user']

  if (currentUser != null && currentUser.role === 'customers') {
    // Redirect to frontend
    window.location.assign(process.env.VUE_APP_FRONTEND_HOST + '/app')
    return
  }

  const unauthenticatedAccess = to.matched.every((record) => record.meta.unauthenticatedAccess)
  if (unauthenticatedAccess === false && !currentUser) {
    next('/login')
  } else {
    next()
  }
})

async function initApp () {
  try {
    await store.dispatch('auth/authenticate')
  } catch (error) {
    console.error(error)
    window.localStorage.removeItem('feathers-jwt')
  }

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}

initApp()
