<template>
  <div class="subscription-renew-form">
    <div class="modal-card" style="margin: 0 auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Abo mit Rechnungszahlung verlängern</p>
      </header>
      <section class="modal-card-body">
        <b-loading :active="isCreatingRenewal"></b-loading>

        <b-message type="is-danger" v-if="errorOnCreate">{{ errorOnCreate }}</b-message>
        <div v-if="subscription != null && newSubscription != null">
          <b-field label="Bisherige Lizenz">
            {{ subscription.licenseObject ? subscription.licenseObject.title : '-' }}
            |
            {{ subscription.licenseObject ? subscription.licenseObject.size : '-' }}
            Plätze |
            {{ subscription.licenseObject ? subscription.licenseObject.period : '-' }}
            Monate
          </b-field>

          <b-field label="Neue Lizenz" grouped v-if="newSubscription">
            <div class="control">
              {{ newSubscription.licenseObject ? newSubscription.licenseObject.title : '-' }}
              |
              {{ newSubscription.licenseObject ? newSubscription.licenseObject.size : '-' }}
              Plätze |
              {{ newSubscription.licenseObject ? newSubscription.licenseObject.period : '-' }}
              Monate
            </div>
            <div class="control">
              <b-button @click="onEditLicenseButtonClicked" size="is-small">
                Lizenz {{ newSubscription.license ? 'ändern' : 'auswählen' }}
              </b-button>
            </div>
          </b-field>

          <b-message type="is-danger" v-if="usersLosingAccess > 0">
            Bei dieser Verlängerung
            {{ usersLosingAccess > 1 ? 'werden' : 'wird' }}
            {{ usersLosingAccess }} Nutzer den Zugang verlieren, da die neue Lizenz kleiner ist!
          </b-message>

          <b-field label="Verlängerungszeitraum">
            <b-taglist attached>
              <b-tag>
                {{ renewalStart | dateTimeAt }}
              </b-tag>
              <b-tag>
                <b-icon icon="arrow-right" />
              </b-tag>
              <b-tag>
                {{ renewalEnd | dateTimeAt }}
              </b-tag>
            </b-taglist>
          </b-field>

          <invoice-data-form
            ref="invoiceDataForm"
            v-model="newSubscription.invoice"
            :license="newSubscription.licenseObject"
            :customer="customer"
            :editable="true"
            size="is-small"
          />

          <b-field label="Notiz">
            <b-input v-model="newSubscription.adminNotes" type="textarea" expanded />
          </b-field>

          <p class="mb-3">
            Beim Anlegen der Verlängerung erhält der Kunde eine E-Mail, dass eine Verlängerung erstellt wird und die
            HHO-Buchhaltung erhält eine Mail mit der Bitte um Rechnungsstellung.
          </p>

          <b-button type="is-primary" expanded @click="onSubmitButtonClicked" :loading="isCreatingRenewal">
            Verlängerung anlegen und E-Mails verschicken
          </b-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import LicenseEditorModal from '@/components/LicenseEditorModal'
import InvoiceDataForm from '@/components/InvoiceDataForm'
import { License } from '@custom-media/signdigital-lib/src/license'
import * as Sentry from '@sentry/browser'
import dayjs from 'dayjs'
export default {
  props: {
    subscription: null,
    customer: null
  },
  components: {
    InvoiceDataForm
  },
  data () {
    return {
      newSubscription: null,
      isCreatingRenewal: false,
      errorOnCreate: null
    }
  },
  computed: {
    usersLosingAccess () {
      if (!this.newSubscription?.licenseObject || !this.subscription.licenseObject) {
        return 0
      }

      return Math.max(this.subscription.users.length - this.newSubscription.licenseObject.size, 0)
    },
    notePrefix () {
      const timestamp = dayjs(new Date()).format('DD.MM.YYYY [um] HH:mm [Uhr]')
      // const url = process.env.VUE_APP_FRONTEND_HOST + '/backend/app/subscription/' + this.subscription._id
      let note = `Verlängerung von Abo ${this.subscription._id} erstellt von ${this.$store.getters['auth/user'].name} am ${timestamp}.`
      if (this.renewalStatus === 'planned') {
        note += `\nVerlängerung wird am ${dayjs(this.renewalStart).format('DD.MM.YYYY [um] HH:mm [Uhr]')} aktiv.`
      } else {
        note += '\nDie Verlängerung ist ab sofort aktiv.'
      }
      return note
    },
    renewalStatus () {
      if (this.subscription.status === 'canceled' || this.subscription.periodEnd < new Date()) {
        return 'active'
      } else {
        return 'planned'
      }
    },
    renewalStart () {
      const now = new Date()
      if (this.renewalStatus === 'active') {
        return now
      } else {
        return this.subscription.periodEnd
      }
    },
    renewalEnd () {
      if (!this.newSubscription?.licenseObject) {
        return null
      }
      return dayjs(this.renewalStart).add(this.newSubscription.licenseObject.period, 'month').toDate()
    }
  },
  mounted () {
    this.newSubscription = new this.$FeathersVuex.api.Subscription({
      status: this.renewalStatus,
      owner: this.subscription.owner,
      license: this.subscription.license,
      adminNotes: this.notePrefix,
      predecessor: this.subscription._id
    })
  },
  methods: {
    onEditLicenseButtonClicked () {
      this.$buefy.modal.open({
        parent: this,
        component: LicenseEditorModal,
        canCancel: ['escape', 'outside'],
        props: {
          licenseId: this.newSubscription.license
        },
        events: {
          submit: (licenseId) => {
            const license = new License(licenseId)
            this.newSubscription.license = licenseId
            this.newSubscription.licenseObject = license

            if (license.value) {
              // Calculate pricing here
              this.$refs.invoiceDataForm.updateFromNet(license.value)
            } else {
              this.$refs.invoiceDataForm.clear()
            }
          }
        }
      })
    },
    async onSubmitButtonClicked () {
      this.newSubscription.periodStart = this.renewalStart
      this.newSubscription.periodEnd = this.renewalEnd
      this.newSubscription.users = this.subscription.users.slice(0, this.newSubscription.license.size)

      try {
        this.isCreatingRenewal = true
        this.errorOnCreate = null
        await this.newSubscription.create()
        console.log(this.newSubscription)

        // this.subscription.successor = this.newSubscription._id
        // console.log(this.subscription)
        // await this.subscription.save()

        this.$emit('close')
        this.$emit('submit', this.newSubscription._id)
      } catch (error) {
        Sentry.captureException(error)
        console.error(error)
        this.errorOnCreate = error
      } finally {
        this.isCreatingRenewal = false
      }
    }
  }
}
</script>
