<template>
  <div class="subscriptions-card">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title">{{ title }}</p>
      </div>
      <div class="card-content">
        <div class="content">
          <b-table :loading="isFindSubscriptionsPending" :data="subscriptions" @click="onRowClicked">
            <b-table-column label="Kunde" v-slot="{ row: subscription }">
              <span v-if="subscription.owner">{{ subscription.owner.name }} ({{ subscription.owner.email }})</span>
            </b-table-column>
            <b-table-column label="Status" v-slot="{ row: subscription }">
              <subscription-status-tag :subscription="subscription" />
            </b-table-column>
            <b-table-column label="Typ" v-slot="{ row: subscription }">
              <subscription-type-tag :subscription="subscription" />
            </b-table-column>
            <b-table-column label="Geändert" v-slot="{ row: subscription }">
              <span class="tag">{{ subscription.updatedAt | dateTimeAt }}</span>
            </b-table-column>
            <template #empty>
              <p class="has-text-centered">Alles erledigt <b-icon icon="check-circle" /></p>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex'
import SubscriptionTypeTag from '@custom-media/signdigital-web-shared/src/components/SubscriptionTypeTag'
import SubscriptionStatusTag from '@custom-media/signdigital-web-shared/src/components/SubscriptionStatusTag'
export default {
  mixins: [
    makeFindMixin({
      service: 'subscriptions',
      items: 'subscriptions'
    })
  ],
  components: {
    SubscriptionTypeTag,
    SubscriptionStatusTag
  },
  props: ['title', 'status'],
  computed: {
    subscriptionsParams () {
      const statuses = Array.isArray(this.status) ? this.status : [this.status]
      return {
        query: {
          status: {
            $in: statuses
          },
          $sort: {
            updatedAt: '-1'
          }
        }
      }
    }
  },
  methods: {
    onRowClicked (subscription) {
      this.$router.push({ name: 'subscription-details', params: { id: subscription._id } })
    }
  }
}
</script>
