<template>
  <div class="admin-details">
    <b-loading :active="isLoading" :is-full-page="false" />

    <!-- Department -->
    <validation-provider name="Abteilung" v-slot="{ errors }" immediate slim>
      <b-field label="Abteilung" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="text" v-model="admin.department" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Company -->
    <validation-provider name="Notizen" v-slot="{ errors }" immediate slim>
      <b-field label="Notizen" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="textarea" v-model="admin.notes" :disabled="!isEditable" />
      </b-field>
    </validation-provider>
  </div>
</template>

<script>
import { makeEditorMixin } from '@/mixins/editor-mixins.js'
export default {
  mixins: [makeEditorMixin({ service: 'admins', useProps: true })],
  components: {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.customer-details {
  position: relative;
}
</style>
