<template>
  <div class="customer-details">
    <b-loading :active="isLoading" :is-full-page="false" />

    <!-- Customer type -->
    <validation-provider name="Kundentyp" v-slot="{ errors }" immediate slim>
      <b-field label="Kundentyp" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-radio-button v-model="customer.customerType" native-value="person" type="is-primary" :disabled="!isEditable">
          <span>Privatkunde</span>
        </b-radio-button>
        <b-radio-button
          v-model="customer.customerType"
          native-value="company"
          type="is-primary"
          :disabled="!isEditable"
        >
          <span>Geschäftskunde</span>
        </b-radio-button>
      </b-field>
    </validation-provider>

    <!-- Company -->
    <validation-provider name="Firma (Firmenkunden)" immediate v-slot="{ errors }" slim>
      <b-field label="Firma (Firmenkunden)" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="text" v-model="customer.company" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Name -->
    <validation-provider name="Name" v-slot="{ errors }" immediate slim>
      <b-field label="Name" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input v-model="customer.name" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Additional -->
    <validation-provider name="Abteilung, Apartment, Gebäude (optional)" v-slot="{ errors }" immediate slim>
      <b-field
        label="Abteilung, Apartment, Gebäude (optional)"
        :message="errors[0]"
        :type="{ 'is-danger': errors.length > 0 }"
      >
        <b-input type="text" v-model="customer.additional" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Street -->
    <validation-provider name="Straße" v-slot="{ errors }" immediate slim>
      <b-field label="Straße" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="text" v-model="customer.street" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Number -->
    <validation-provider name="Hausnummer" v-slot="{ errors }" immediate>
      <b-field label="Hausnummer" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }" slim>
        <b-input type="text" v-model="customer.number" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Postcode -->
    <validation-provider name="Postleitzahl" v-slot="{ errors }" immediate slim>
      <b-field label="Postleitzahl" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="text" v-model="customer.postcode" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- City -->
    <validation-provider name="Stadt" v-slot="{ errors }" immediate slim>
      <b-field label="Stadt" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="text" v-model="customer.city" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Country -->
    <validation-provider name="Land" rules="" v-slot="{ errors }" immediate slim>
      <b-field label="Land" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <country-select v-model="customer.countryCode" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Reference -->
    <validation-provider name="Interne Referenz" v-slot="{ errors }" immediate slim>
      <b-field label="Interne Referenz" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="text" v-model="customer.reference" :disabled="!isEditable" />
      </b-field>
    </validation-provider>

    <!-- Vat id -->
    <validation-provider name="USt.-Id (Firmenkunden)" v-slot="{ errors }" immediate slim>
      <b-field label="USt.-Id (Firmenkunden)" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
        <b-input type="text" v-model="customer.vatId" :disabled="!isEditable" />
      </b-field>
    </validation-provider>
  </div>
</template>

<script>
import { makeEditorMixin } from '@/mixins/editor-mixins.js'
import CountrySelect from '@custom-media/signdigital-web-shared/src/components/CountrySelect.vue'
export default {
  mixins: [makeEditorMixin({ service: 'customers', useProps: true })],
  components: {
    CountrySelect
  },
  methods: {
    prepareCustomer (customer) {
      customer.customerType = 'person'
      return customer
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-details {
  position: relative;
}
</style>
