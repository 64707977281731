<template>
  <div class="special-permissions-input">
    <b-field v-for="permission in permissions" :key="permission.name" :message="permission.description">
      <b-checkbox
        :disabled="disabled"
        :value="hasPermission(permission.key)"
        @input="onPermissionChange(permission.key)"
      >
        {{ permission.title }}
      </b-checkbox>
    </b-field>
  </div>
</template>

<script>
const permissions = [
  {
    key: 'multiplicator',
    title: 'Referent / Multiplikator',
    description:
      'Benutzer mit dieser Berechtigung können das Logo in Druckvorlagen durch ein eigenes ersetzen (das SIGNdigital-Logo erscheint dann in der Fußzeile) und einen eigenen Text in die Fußzeile einfügen.'
  },
  {
    key: 'multisession',
    title: 'Fortbildungs-Nutzer',
    description:
      'Benutzer mit dieser Berechtigung können sich auf bis zu 50 Geräten anstatt der regulären 2 Geräte anmelden.'
  }
]
export default {
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localValue: [],
      permissions
    }
  },
  computed: {
    hasPermission () {
      return (value) => this.localValue.includes(value)
    }
  },
  watch: {
    value () {
      this.localValue = this.value != null ? [...this.value] : []
    }
  },
  methods: {
    onPermissionChange (key) {
      const i = this.localValue.indexOf(key)
      const hasPermission = i !== -1
      if (hasPermission) {
        const newValue = this.localValue.filter((v) => v !== key)
        this.$emit('input', newValue)
      } else {
        this.$emit('input', [...this.localValue, key])
      }
    }
  }
}
</script>
