<template>
  <div class="subscription-details">
    <editor-navbar
      title="Details des Abos"
      create-title="Neues Abo erstellen"
      edit-title="Abo bearbeiten"
      :name="subscription._id"
      :is-editing="isEditing"
      :is-creating="isCreating"
      @reset="onResetButtonClicked"
      @edit="onEditButtonClicked"
      @close="onCloseButtonClicked"
      @save="onSaveButtonClicked"
    />

    <div class="container p-6">
      <div class="has-background-white p-5" v-if="subscription">
        <div class="buttons" v-if="!isCreating">
          <b-button
            v-if="subscription.subscriptionType === 'stripe'"
            :disabled="isEditing"
            @click="onStripeSyncButtonClicked"
            :loading="isUpdatePending"
          >
            Abruf von Stripe-Daten
          </b-button>
          <b-button :disabled="isEditing" @click="onProvisionUserAccessButtonClicked" :loading="isUpdatePending">
            Nutzer-Zugänge aktualisieren
          </b-button>
        </div>

        <!-- PENDING-APPROVAL-MESSAGE -->
        <b-message type="is-warning" v-if="!isEditing && !isCreating && subscription.status === 'pending-approval'">
          Der Status diese Bestellung ist „Warten auf Bestätigung”.
          <br />
          Da es sich um eine manuelle Bestellung auf Rechnung handelt, wurde eine E-Mail mit den Rechnungsdaten an die
          Buchhaltung geschickt. Sobald der Besteller überprüft ist, die Rechnung erstellt und an den Kunden gesendet
          wurde, kann dieses Abo bestätigt werden.

          <b-field
            class="mt-4"
            label="Interne Anmerkungen"
            message="z.B. zum Hinzufügen der internen Rechnungs-Referenz. Wird beim Bestätigen dem Abo hinzugefügt."
          >
            <b-input v-model="approvalNote" placeholder="Rechnungs-Referenz, Anmerkungen o.ä." type="textare"></b-input>
          </b-field>
          <div class="buttons mt-4">
            <b-button @click="onApproveButtonClicked" type="is-primary">Abo bestätigen und freischalten</b-button>
            <b-button @click="onCancelButtonClicked" type="is-danger">Abo stornieren</b-button>
          </div>
        </b-message>

        <!-- DETAILS-PANEL -->
        <h2 class="subtitle">Eigenschaften</h2>
        <div class="columns">
          <div class="column">
            <b-message v-if="isEditable && subscription.subscriptionType === 'stripe'">
              <p>Dieses Abo wird über Stripe abgerechnet und muss in Stripe verwaltet werden.</p>
              <stripe-ref resource="subscriptions" :id="subscription.stripeSubscriptionId" />
            </b-message>

            <!-- Select license -->
            <b-button v-else-if="isEditable" @click="onEditLicenseButtonClicked" class="mb-4">
              Lizenz {{ subscription.license ? 'ändern' : 'auswählen' }}
            </b-button>

            <b-field label="Name der Lizenz">
              {{ subscription.licenseObject ? subscription.licenseObject.title : '-' }}
            </b-field>
            <b-field label="Anzahl Plätze">
              {{ subscription.licenseObject ? subscription.licenseObject.size : '-' }}
            </b-field>
            <b-field label="Laufzeit in Monaten">
              {{ subscription.licenseObject ? subscription.licenseObject.period : '-' }}
            </b-field>
            <b-field label="Artikelnummer">
              {{ subscription.licenseObject ? subscription.licenseObject.id : '-' }}
            </b-field>
            <validation-provider
              name="Abrechnungsperiode endet am"
              rules="periodEndValidation:@Status"
              immediate
              v-slot="{ errors, valid }"
            >
              <b-field
                v-if="subscription.licenseObject"
                class="mt-2"
                label="Abrechnungsperiode endet am"
                :type="{
                  'is-danger': errors.length > 0,
                  'is-primary': valid
                }"
                :message="errors[0]"
              >
                <div>
                  <b-datetimepicker
                    v-model="subscription.periodEnd"
                    placeholder="Ende der Abrechnungsperiode eintragen"
                    icon="calendar"
                    icon-right="times-circle"
                    icon-right-clickable
                    @icon-right-click="subscription.periodEnd = null"
                    horizontal-time-picker
                    :datetime-formatter="datetimeFormatter"
                    :disabled="!isEditable || subscription.subscriptionType === 'stripe'"
                  ></b-datetimepicker>
                  <div
                    class="buttons mt-2"
                    v-if="(isEditing || isCreating) && !subscription.periodEnd && subscription.licenseObject"
                  >
                    <b-button size="is-small" @click="setDefaultPeriodEnd">
                      Auf {{ subscription.licenseObject.period }}
                      {{ 'Monat' | pluralize(subscription.licenseObject.period, 'e') }}
                      in der Zukunft setzen
                    </b-button>
                  </div>
                </div>
              </b-field>
            </validation-provider>

            <b-field label="Automatische Verlängerung" class="mt-2">
              <b-switch
                :disabled="subscription.subscriptionType === 'manual' || (!isEditing && !isCreating)"
                :value="!subscription.cancelAtPeriodEnd"
                @input="subscription.cancelAtPeriodEnd = !$event"
              >
                {{ subscription.cancelAtPeriodEnd ? 'Deaktiviert' : 'Aktiviert' }}
              </b-switch>
            </b-field>
          </div>
          <div class="column">
            <!-- Status -->
            <validation-provider
              name="Status"
              rules="statusValidation:@Abrechnungsperiode endet am"
              immediate
              v-slot="{ errors, valid }"
            >
              <b-field
                label="Status"
                :type="{
                  'is-danger': errors.length > 0,
                  'is-primary': valid
                }"
                :message="errors[0]"
              >
                <subscription-status-tag :subscription="subscription" v-if="!isEditing && !isCreating" />
                <b-select v-model="subscription.status" v-else>
                  <option value="active">
                    {{ $options.filters.subscriptionStatus('active') }}
                  </option>
                  <option value="overdue">
                    {{ $options.filters.subscriptionStatus('overdue') }}
                  </option>
                  <option value="canceled">
                    {{ $options.filters.subscriptionStatus('canceled') }}
                  </option>
                  <option value="incomplete">
                    {{ $options.filters.subscriptionStatus('incomplete') }}
                  </option>
                  <option value="pending-approval">
                    {{ $options.filters.subscriptionStatus('pending-approval') }}
                  </option>
                </b-select>
              </b-field>
            </validation-provider>

            <!-- Subscription type -->
            <b-field label="Typ">
              <subscription-type-tag :subscription="subscription" :customer="subscription.cust" />
            </b-field>

            <div v-if="subscription.subscriptionType === 'stripe'">
              <b-field label="Stripe-Abo">
                <stripe-ref resource="subscriptions" :id="subscription.stripeSubscriptionId" />
              </b-field>
            </div>
          </div>
        </div>
        <section>
          <h2 class="subtitle">Rechnungsdaten</h2>
          <b-message v-if="!subscription.licenseObject">Bitte zuerst die Lizenz auswählen</b-message>
          <invoice-data-form
            ref="invoiceDataForm"
            v-model="subscription.invoice"
            :license="subscription.licenseObject"
            :customer="customer"
            :editable="isEditable"
          />
        </section>
        <section v-if="subscription.subscriptionType === 'manual' && !isCreating">
          <hr />

          <h2 class="subtitle">Verlängerung von Rechnungs-Abos</h2>
          <div class="buttons">
            <b-button
              v-if="subscription.subscriptionType === 'manual'"
              :disabled="subscription.successor != null || isEditing"
              @click="onRenewSubscriptionButtonClicked"
            >
              Dieses Abo {{ subscription.status === 'active' ? 'vorzeitig ' : '' }}verlängern
            </b-button>
          </div>

          <b-field label="Verlängerung dieses Abos:">
            <entity-ref
              v-if="subscription.successor"
              type="subscription"
              :entity="subscription.successor"
              icon="arrow-right-from-line"
            />
            <b-tag type="is-light" v-else>Keine</b-tag>
          </b-field>

          <b-field label="Abo, das durch dieses Abo verlängert wird/wurde:">
            <entity-ref
              v-if="subscription.predecessor"
              type="subscription"
              :entity="subscription.predecessor"
              icon="arrow-right-to-line"
            />
            <b-tag type="is-light" v-else>Keins</b-tag>
          </b-field>

          <b-field label="Benachrichtigung über ablaufendes Abo:">
            <b-tag v-if="subscription.expirationWarningSentAt">
              Versendet am {{ subscription.expirationWarningSentAt | dateTimeAt }}
            </b-tag>
            <b-tag v-else>Nicht versendet</b-tag>
          </b-field>
        </section>

        <!-- Users -->
        <hr />
        <h2 class="subtitle">Zugehörige Nutzer</h2>
        <div class="columns">
          <div class="column">
            <div v-if="owner">
              <b-field label="Besitzer und Nutzer für Abrechnung">
                <entity-ref
                  v-if="!isEditable"
                  type="user"
                  :entity="owner"
                  resolve
                  :name-formatter="(e) => `${e.name} (${e.email})`"
                />
                <b-input type="text" v-model="subscription.owner" v-else />
              </b-field>

              <b-field label="Rechnungsadresse" class="mb-4">
                <billing-address v-if="customer" :customer="customer" />
              </b-field>

              <b-field label="Team" v-if="owner && owner.team" class="mb-4">
                <entity-ref
                  type="team"
                  :entity="owner.team"
                  resolve
                  :name-formatter="(e) => `${e.name} (${e.members.length} Teammitglieder)`"
                />
              </b-field>
            </div>

            <div class="buttons">
              <b-button
                size="is-small"
                :disabled="subscription.subscriptionType !== 'manual'"
                @click="onTransferButtonClicked"
              >
                Abo (und zugehöriges Team) auf anderen Nutzer umschreiben
              </b-button>
            </div>

            <b-field label="Benutzer, die diesem Abo zugeordnet sind">
              <ul>
                <li :key="user._id" v-for="user in subscription.users">
                  <entity-ref
                    v-if="!isEditable"
                    type="user"
                    :entity="user"
                    resolve
                    :name-formatter="(e) => `${e.name} (${e.email})`"
                  />
                </li>
              </ul>
            </b-field>
          </div>
        </div>

        <!-- Additional Infos -->
        <hr />
        <h2 class="subtitle">Sonstige Infos</h2>
        <b-field class="mt-4" label="Interne Notizen">
          <b-input
            :disabled="!isEditable"
            v-model="subscription.adminNotes"
            placeholder="Rechnungs-Referenz, Anmerkungen o.ä."
            type="textarea"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionTypeTag from '@custom-media/signdigital-web-shared/src/components/SubscriptionTypeTag'
import SubscriptionStatusTag from '@custom-media/signdigital-web-shared/src/components/SubscriptionStatusTag'
import BillingAddress from '@custom-media/signdigital-web-shared/src/components/BillingAddress.vue'
import LicenseEditorModal from '@/components/LicenseEditorModal'
import SubscriptionRenewModal from '@/components/SubscriptionRenewModal'
import EditorNavbar from '@/components/EditorNavbar'
import EntityRef from '@/components/EntityRef'
import { License } from '@custom-media/signdigital-lib/src/license'
import StripeRef from '@/components/StripeRef'
import { makeEditorMixin } from '@/mixins/editor-mixins.js'
import dayjs from 'dayjs'
import { extend } from 'vee-validate'
import { mapState } from 'vuex'
import InvoiceDataForm from '../components/InvoiceDataForm'
// import { License } from '@custom-media/signdigital-lib/src/license'

extend('statusValidation', {
  params: ['periodEnd'],
  validate (value, { periodEnd }) {
    const needsPeriodEnd = value === 'active' || value === 'incomplete'
    if (!needsPeriodEnd) {
      return true
    } else {
      // Should be in the future
      return periodEnd && periodEnd > new Date()
    }
  },
  message: 'Bei einem aktiven Abo muss das Ende der Abrechnungsperiode in der Zukunft liegen'
})
extend('periodEndValidation', {
  params: ['status'],
  validate (value, { status }) {
    const needsPeriodEnd = status === 'active' || status === 'incomplete'
    if (!needsPeriodEnd) {
      return true
    } else {
      // Should be in the future
      return value > new Date()
    }
  },
  message: 'Bei einem aktiven Abo muss das Ende der Abrechnungsperiode in der Zukunft liegen'
})

export default {
  mixins: [makeEditorMixin({ service: 'subscriptions' })],
  components: {
    EditorNavbar,
    SubscriptionTypeTag,
    SubscriptionStatusTag,
    BillingAddress,
    StripeRef,
    EntityRef,
    InvoiceDataForm
  },
  data () {
    return {
      approvalNote: null,
      owner: null,
      customer: null
    }
  },
  computed: {
    ...mapState('subscriptions', ['isUpdatePending'])
  },
  mounted () {},
  methods: {
    async afterInit () {
      if (typeof this.subscription.owner === 'string') {
        this.owner = await this.$store.dispatch('users/get', this.subscription.owner)

        if (this.owner.role === 'customers') {
          this.customer = await this.$store.dispatch('customers/get', this.owner.entity)
        }
      }
    },
    async onApproveButtonClicked () {
      this.subscription.status = 'active'
      if (this.subscription.periodEnd === null) {
        this.setDefaultPeriodEnd()
      }

      // Add note, who approved this order
      const formattedDate = this.datetimeFormatter(new Date())
      const approvalUserNote = `[Freigegeben durch ${this.$store.getters['auth/user'].name} am ${formattedDate}]`
      this.subscription.adminNotes = (this.subscription.adminNotes ?? '') + `\n${approvalUserNote}`

      // Add approval reference if provided
      if (this.approvalNote) {
        this.subscription.adminNotes = (this.subscription.adminNotes ?? '') + `\n[Freigabe-Notiz: ${this.approvalNote}]`
      }

      await this.subscription.save()
      this.approvalNote = null
    },
    async onCancelButtonClicked () {
      this.subscription.status = 'canceled'
      await this.subscription.save()
    },
    setDefaultPeriodEnd () {
      this.subscription.periodEnd = dayjs().add(this.subscription.licenseObject.period, 'month').toDate()
    },
    datetimeFormatter (date) {
      return dayjs(date).format('DD.MM.YYYY [um] HH:mm [Uhr]')
    },
    datetimeParser (value) {
      return dayjs(value).toDate()
    },
    async onStripeSyncButtonClicked () {
      await this.$store.dispatch('subscriptions/update', [this.subscription._id, {}, { query: { source: 'stripe' } }])
      this.init()
    },
    async onRenewSubscriptionButtonClicked () {
      this.$buefy.modal.open({
        parent: this,
        component: SubscriptionRenewModal,
        canCancel: ['escape', 'outside'],
        props: {
          subscription: this.subscription,
          customer: this.customer
        },
        events: {
          submit: async (successorId) => {
            this.subscription.successor = successorId
            await this.subscription.save()
            this.init()
          }
        }
      })
    },

    async onTransferButtonClicked () {
      this.$buefy.dialog.prompt({
        message: 'An welche:n Nutzer:in soll das Abo übertragen werden (E-Mail Adresse eines bestehenden Kontos)?',
        inputAttrs: {
          placeholder: 'max@mustermann.de',
          type: 'email'
        },
        trapFocus: true,
        onConfirm: async (value) => {
          try {
            await this.subscription.patch({ data: { newOwnerEmail: value } })

            this.$buefy.toast.open({
              type: 'is-success',
              message: 'Abo aktualisiert'
            })
            this.init()
          } catch (error) {
            console.error(error)
            this.$buefy.toast.open({
              type: 'is-danger',
              message: 'Fehler: ' + error
            })
            this.init()
          }
        }
      })
    },
    prepareSubscription (subscription) {
      if (this.isCreating && this.$route.query?.userId) {
        subscription.owner = this.$route.query?.userId
        subscription.users = [this.$route.query?.userId]
      }
      return subscription
    },
    onEditLicenseButtonClicked () {
      this.$buefy.modal.open({
        parent: this,
        component: LicenseEditorModal,
        canCancel: ['escape', 'outside'],
        props: {
          licenseId: this.subscription.license
        },
        events: {
          submit: (licenseId) => {
            this.subscription.license = licenseId
            this.subscription.licenseObject = new License(licenseId)

            if (this.subscription.licenseObject.value && !this.subscription.invoice?.gross) {
              this.$nextTick(() => {
                this.$refs.invoiceDataForm.onApplyProposalsButtonClicked()
              })
            }
          }
        }
      })
    },
    async onProvisionUserAccessButtonClicked () {
      await this.subscription.patch()
    }
  }
}
</script>
