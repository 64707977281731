import axios from 'axios'

export const apiMixin = {
  methods: {
    async createAccountingExport (accessToken, month) {
      const apiHost = process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : 'http://localhost:3030'
      const endpoint = `${apiHost}/accounting-export`
      const data = {
        month
      }
      return axios.post(endpoint, data, {
        headers: {
          Authorization: accessToken
        }
      })
    }
  }
}
