<template>
  <div class="default-license-select">
    <b-dropdown
      v-model="localLicense"
      @input="onLicenseChanged"
      scrollable
      :max-height="500"
      expanded
      :disabled="disabled"
      class="mb-4"
      append-to-body
    >
      <template #trigger>
        <b-button
          :label="localLicense ? localLicense.title : 'Standard-Lizenzpaket auswählen'"
          type="is-primary"
          icon-right="chevron-down"
          expanded
        />
      </template>
      <b-dropdown-item v-for="license in licenses" :key="license.id" :value="license">
        <p class="mb-2 is-flex is-align-items-center">
          <span class="is-size-6 has-text-weight-bold">{{ license.title }}</span>
          <span class="ml-4 is-size-7 is-family-monospace">{{ license.id }}</span>
        </p>
        <b-taglist>
          <b-tag type="is-primary">{{ license.size }} {{ 'Platz' | pluralizeReplace(license.size, 'Plätze') }}</b-tag>
          <b-tag type="is-primary">{{ license.period }} {{ 'Monat' | pluralize(license.period, 'e') }}</b-tag>
          <b-tag v-if="license.stripePriceId" type="is-info">Stripe</b-tag>
          <b-tag v-if="license.stripePriceId" type="is-dark">Rechnungszahlung</b-tag>
          <b-tag v-else type="is-dark">Nur Rechnungszahlung</b-tag>
        </b-taglist>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import licenseDefinitions from '@custom-media/signdigital-lib/src/constants/license-definitions'
import { License } from '@custom-media/signdigital-lib/src/license'
const licenseIds = licenseDefinitions.reduce((ids, lg) => [...ids, ...lg.licenses.map((l) => l.id)], [])
const licenses = licenseIds.map((id) => new License(id))
export default {
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      licenses,
      localLicense: null
    }
  },
  mounted () {
    this.updateLocalLicense()
  },
  watch: {
    value () {
      this.updateLocalLicense()
    }
  },
  methods: {
    updateLocalLicense () {
      if (this.value) {
        this.localLicense = new License(this.value)
      }
    },
    onLicenseChanged (license) {
      if (license.id !== this.value) {
        this.$emit('input', license.id)
      }
    }
  }
}
</script>
