<template>
  <div class="user-reference is-relative">
    <b-loading v-if="!(id && resource)" :is-full-page="false" />
    <span v-else>
      <a :href="url" target="_blank">
        <b-tag type="is-info">{{ id }} <b-icon icon="external-link" /></b-tag>
      </a>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    resource: String
  },
  computed: {
    url () {
      const env = process.env.VUE_APP_ENVIRONMENT === 'production' ? '' : '/test'
      return `https://dashboard.stripe.com${env}/${this.resource}/${this.id}`
    }
  }
}
</script>
