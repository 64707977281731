// src/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './store.auth'
import feathersClient, { makeServicePlugin, BaseModel, FeathersVuex } from '../feathers-rest-client'
import makePreferencesModule from '@custom-media/signdigital-web-shared/src/store/preferences/preferences'
import cargo from '@custom-media/signdigital-web-shared/src/store/cargo/cargo'

Vue.use(Vuex)
Vue.use(FeathersVuex)

const plugins = [
  'admins',
  'categories',
  'custom-signs',
  'customers',
  'documents',
  'files',
  'images',
  'lists',
  'list-templates',
  'sign-requests',
  'signs',
  'subscriptions',
  'teams',
  'users'
]

const feathersVuexPlugins = plugins
  .map((name) => require(`@custom-media/signdigital-web-shared/src/store/services/${name}.js`).default)
  .map((initServicePlugin) => initServicePlugin(makeServicePlugin, feathersClient, BaseModel))

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    preferences: makePreferencesModule(feathersClient),
    cargo
  },
  plugins: [...feathersVuexPlugins, auth]
})
