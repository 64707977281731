const { startCase, kebabCase, camelCase, capitalize, isEqual } = require('lodash')

export function makeEditorMixin (options) {
  const SERVICE = options.service
  const SERVICE_SINGULAR = SERVICE.substring(0, SERVICE.length - 1)
  const ITEM = options.item ?? camelCase(SERVICE_SINGULAR)
  const ITEM_ID = options.itemId ?? ITEM + 'Id'
  const MODEL = options.model ?? startCase(camelCase(SERVICE_SINGULAR)).replace(/ /g, '')
  const PREPARE_MODEL = options.prepareModel ?? 'prepare' + MODEL
  const PREPARE_MODEL_SAVE = options.prepareModelSave ?? 'prepare' + MODEL + 'Save'
  const LIST_ROUTE = kebabCase(SERVICE)
  const DETAILS_ROUTE = kebabCase(SERVICE_SINGULAR) + '-details'
  return {
    data () {
      return {
        [ITEM]: new this.$FeathersVuex.api[MODEL]()
      }
    },
    props: {
      editing: {
        type: Boolean,
        default: null
      },
      creating: {
        type: Boolean,
        default: null
      },
      action: {
        type: String,
        default: null
      },
      [ITEM_ID]: {
        type: String,
        default: null
      }
    },
    computed: {
      routeAction () {
        return this.$route.query?.action ?? null
      },
      isEditing () {
        return options.useProps ? this.editing : this.routeAction === 'edit'
      },
      isCreating () {
        return options.useProps ? this.creating : this.routeAction === 'create'
      },
      isEditable () {
        return this.isEditing || this.isCreating
      },
      isLoading () {
        return (
          this.$store.state[SERVICE].isGetPending ||
          this.$store.state[SERVICE].isPatchPending ||
          this.$store.state[SERVICE].isCreatePending
        )
      }
    },
    watch: {
      '$route.params.id' () {
        console.log('Route param id changed')
        this.init()
      },
      [ITEM_ID] () {
        if (options.useProps) {
          this.init()
        }
      }
    },
    created () {
      console.log('Created editor-mixin for: ' + MODEL)
      this.init()
    },
    methods: {
      async init () {
        const Model = this.$FeathersVuex.api[MODEL]
        if (this.isCreating) {
          this[ITEM] = await this[PREPARE_MODEL](new Model())
        } else {
          const itemId = options.useProps ? this[ITEM_ID] : this.$route.params?.id
          if (itemId != null) {
            this[ITEM] = (await Model.get(itemId)).clone()
          }
        }

        if (this.afterInit && typeof this.afterInit === 'function') {
          await this.afterInit()
        }
      },
      onResetButtonClicked () {
        if (this.isCreating) {
          this.onCloseButtonClicked()
        }
        this.$emit('before-reset')
        this[ITEM].reset()
        this.$emit('after-reset')
        this.$router.replace({ name: DETAILS_ROUTE, params: { id: this.$route.params.id } })
      },
      onCloseButtonClicked () {
        this.$router.replace({ name: LIST_ROUTE })
      },
      onEditButtonClicked () {
        this.$router.replace({ name: DETAILS_ROUTE, params: { id: this.$route.params.id }, query: { action: 'edit' } })
      },
      async [PREPARE_MODEL_SAVE] (model) {
        return model
      },
      async [PREPARE_MODEL] (model) {
        return model
      },
      async onSaveButtonClicked () {
        this.$emit('before-save')
        if (this.isCreating) {
          this.$emit('before-create')
        } else {
          this.$emit('before-update')
        }
        const item = await this[PREPARE_MODEL_SAVE](this[ITEM])
        await item.save()
        this.$emit('after-save')
        if (this.isCreating) {
          this.$emit('after-create')
        } else {
          this.$emit('after-update')
        }
        this.$router.replace({ name: DETAILS_ROUTE, params: { id: this[ITEM]._id }, query: {} })
      }
    }
  }
}

export function makeListMixin (options) {
  const SERVICE = options.service
  const SERVICE_SINGULAR = SERVICE.substring(0, SERVICE.length - 1)

  const NAME = options.name ?? SERVICE
  const READABLE_NAME = options.readableName ?? ''
  const READABLE_NAME_WITH_ARTICLE = options.readableNameWithArticle ?? ''

  const ITEMS = options.items ?? NAME
  const PARAMS = camelCase(ITEMS) + 'Params'
  const FIND_ACTION = 'find' + capitalize(NAME)
  // const LIST_ROUTE = kebabCase(SERVICE)
  const DETAILS_ROUTE = kebabCase(SERVICE_SINGULAR) + '-details'
  const PAGINATION_DATA = camelCase(ITEMS) + 'PaginationData'
  const QID = options.qid ?? camelCase('list ' + ITEMS)

  const filters = {}
  if (options.filterableFields) {
    options.filterableFields.forEach((field) => {
      filters[field] = null
    })
  }

  return {
    data () {
      return {
        skip: 0,
        limit: 20,
        query: null,
        filters,
        searchFields: options.searchFields
      }
    },
    computed: {
      [PARAMS] () {
        const params = {
          query: {
            $skip: this.skip,
            $limit: this.limit,
            $sort: {
              createdAt: -1
            }
          },
          paginate: true,
          debounce: 500
        }

        if (options.searchable) {
          if (this.query != null && this.query !== '') {
            if (options.searchFields.length > 1) {
              params.query.$or = options.searchFields.map(this.getQueryForSearchField).filter((q) => q != null)
            } else {
              params.query = {
                ...params.query,
                ...this.getQueryForSearchField(options.searchFields[0])
              }
            }
          }
        }

        if (options.filterable) {
          if (this.filters != null) {
            Object.entries(this.filters).forEach(([key, value]) => {
              if (value != null && value !== '') {
                params.query[key] = value
              }
            })
          }
        }
        return params
      },
      total () {
        return this[PAGINATION_DATA]?.[QID]?.mostRecent?.total ?? 0
      },
      currentPage: {
        get () {
          return Math.floor(this.skip / this.limit) + 1
        },
        set (newPage) {
          this.skip = (newPage - 1) * this.limit
        }
      }
    },
    watch: {
      filters: {
        deep: true,
        handler (newValue, oldValue) {
          this.updateQuery()
        }
      },
      query () {
        this.updateQuery()
      }
    },
    created () {
      this.parseQuery()
    },
    methods: {
      getQueryForSearchField (f) {
        if (f.type === 'ObjectId' && !this.query.match(/^[0-9a-fA-F]{24}$/)) {
          return null
        }
        let q
        if (f.type === 'String' && f.operation === 'regex') {
          q = {
            $regex: this.query,
            $options: f.options ?? 'im'
          }
        } else {
          q = this.query
        }
        return {
          [f.path]: q
        }
      },
      onPageChange (page) {
        this.skip = (page - 1) * this.limit
        this.updateQuery()
      },
      parseQuery () {
        const params = this.$route.query
        const parsedSkip = Number.parseInt(params.skip)
        if (!isNaN(parsedSkip)) {
          this.skip = parsedSkip
        }

        // Parse text search query
        if (options.searchable) {
          if (params.query) {
            this.query = params.query
          } else {
            this.query = ''
          }
        }

        // Parse filterable fields
        if (options.filterable) {
          for (const field of options.filterableFields) {
            const paramValue = params[field]
            const parsedValue = paramValue === 'true' ? true : paramValue === 'false' ? false : paramValue
            if (parsedValue !== this.filters[field]) {
              this.filters[field] = parsedValue
            }
          }
        }
      },
      updateQuery () {
        const query = {
          skip: this.skip.toString()
        }

        if (options.searchable && this.query) {
          query.query = this.query
        }

        if (options.filterable && this.filters) {
          for (const [field, value] of Object.entries(this.filters)) {
            if (value == null || value === '') {
              continue
            }

            if (value === true) {
              query[field] = 'true'
            } else if (value === false) {
              query[field] = 'false'
            } else {
              query[field] = value
            }
          }
        }

        if (isEqual(query, this.$router.currentRoute.query)) {
          return
        }
        this.$router.replace({
          query: { ...query }
        })
      },

      onQueryInput () {
        this.skip = 0
        this.updateQuery()
      },

      onFilterInput () {
        this.updateQuery()
      },

      onRowClicked (item) {
        this.$router.push({
          name: DETAILS_ROUTE,
          params: { id: item._id }
        })
      },
      onEditButtonClicked (item) {
        this.$router.push({
          name: DETAILS_ROUTE,
          params: { id: item._id },
          query: { action: 'edit' }
        })
      },
      onCreateButtonClicked () {
        this.$router.push({
          name: DETAILS_ROUTE,
          params: { id: null },
          query: { action: 'create' }
        })
      },
      onDeleteButtonClicked (item) {
        this.$buefy.dialog.confirm({
          title: `${READABLE_NAME || 'Wirklich'} löschen${READABLE_NAME === '' ? ' ?' : ''}`,
          message: `Soll ${READABLE_NAME_WITH_ARTICLE} „${item.name ?? item._id}” endgültig gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.`,
          cancelText: 'Abbrechen',
          confirmText: 'Löschen',
          type: 'is-danger',
          onConfirm: async () => {
            await item.remove()
            this.$buefy.toast.open(' gelöscht')
            this[FIND_ACTION]()
          }
        })
      }
    }
  }
}
