<template>
  <div class="entity-ref is-relative">
    <b-loading v-if="isLoading" :is-full-page="false" />
    <span v-else>
      <span v-if="name" class="mr-3">{{ name }}</span>
      <router-link :to="detailsLink">
        <b-tag :icon="icon" type="is-info">{{ id }} <b-icon icon="external-link" /></b-tag>
      </router-link>
    </span>
  </div>
</template>

<script>
// TODO: Batch resolve requests
export default {
  props: [
    'entity', // String or Model
    'type',
    'nameProperty',
    'nameFormatter',
    'resolve',
    'icon'
  ],
  data () {
    return {
      resolvedEntity: null
    }
  },
  computed: {
    isLoading () {
      return this.object === null && this.id === null
    },
    id () {
      return this.resolvedEntity?._id ?? this.entity
    },
    name () {
      if (this.resolvedEntity && this.nameFormatter) {
        return this.nameFormatter(this.resolvedEntity)
      }
      // if (this.nameProperty) {

      // }
      return this.resolvedEntity?.name
    },
    detailsLink () {
      return {
        name: `${this.type}-details`,
        params: {
          id: this.id
        }
      }
    }
  },
  watch: {
    entity () {
      this.init()
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (typeof this.entity !== 'string') {
        this.resolvedEntity = this.entity
        return
      } else {
        this.resolvedEntity = null
      }

      if (this.resolve !== false) {
        this.resolveEntity()
      }
    },

    async resolveEntity () {
      this.resolvedEntity = await this.$store.dispatch(`${this.type}s/get`, this.id)
    }
  }
}
</script>
