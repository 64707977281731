<template>
  <span class="sign-file-status">
    <b-dropdown :triggers="['click']" aria-role="list" position="is-bottom-left" @active-change="onActiveChanged">
      <!-- Dropdown trigger -->
      <template #trigger>
        <img class="trigger-image" :src="imageSrc" />
      </template>
      <!-- Dropdown content -->
      <template #default>
        <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
          <image-component v-if="isActive && fileType !== 'signVideo' && resolvable != null" :image="resolvable" />
          <video-component
            v-if="isActive && fileType === 'signVideo' && resolvable != null"
            :file="resolvable"
            is-playing
          />
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </span>
</template>

<script>
import { SignImageReference } from '@custom-media/signdigital-lib/src/image-reference'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent'
import VideoComponent from '@custom-media/signdigital-web-shared/src/components/VideoComponent'
const images = require.context('@/assets/img/image-types', false, /\.svg$/)

export default {
  components: {
    ImageComponent,
    VideoComponent
  },
  props: {
    sign: Object,
    fileType: String
  },
  data () {
    return {
      isActive: false,
      resolvable: null
    }
  },
  mounted () {
    this.createResolvable()
  },
  watch: {
    sign () {
      console.log('Sign changed')
      this.createResolvable()
    }
  },
  computed: {
    status () {
      if (this.sign.files?.[this.fileType] == null) {
        return 'danger'
      }
      if (!this.sign.edge?.[this.fileType] == null) {
        return 'warning'
      }
      return 'success'
    },
    imageKey () {
      switch (this.fileType) {
        case 'signBox':
          return 'sign-box'
        case 'signOnly':
          return 'sign-only'
        case 'signMap':
          return 'sign-map'
        case 'signVideo':
          return 'sign-video'
        default:
          return null
      }
    },
    imageSrc () {
      return images(`./${this.imageKey}_${this.status}.svg`)
    }
  },
  methods: {
    createResolvable () {
      console.log('SignFileStatus::createResolvable')
      // switch (this.fileType) {
      //   case 'signBox':
      //   case 'signOnly':
      //   case 'signMap':
      //     /// this.fileReference = new SignImageReference({ signId: this.sign._id, imageType: this.fileType })
      //     break
      //   case 'signVideo':
      //     // this.fileReference = new SignVideoReference({ signId: this.sign._id })
      //     break
      // }

      this.resolvable = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new SignImageReference({
          objectId: this.sign._id,
          imageType: this.fileType
        }),
        variant: 'small'
      })
    },
    onActiveChanged (active) {
      console.log('onActiveChanged', active)
      this.isActive = active
    }
  }
}
</script>

<style lang="scss" scoped>
.trigger-image {
  cursor: pointer;
  width: 32px;
  min-width: 32px;
}
.image-component,
.video-component {
  overflow: hidden;
}
</style>
