<template>
  <div id="teams-view">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="div">
          <div>
            <h1 class="title">Teams</h1>
          </div>
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <search-query-input v-model="query" :search-fields="searchFields" />
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-button type="is-secondary" tag="router-link" :to="{ name: 'team-details', query: { action: 'create' } }"
            >Neues Team anlegen</b-button
          >
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="container p-6">
      <section>
        <b-table
          :data="teams"
          :paginated="true"
          :backend-pagination="true"
          :loading="isFindTeamsPending"
          :per-page="limit"
          :total="total"
          :current-page="currentPage"
          pagination-position="both"
          pagination-size="is-small"
          @page-change="onPageChange"
          @click="onRowClicked"
        >
          <b-table-column label="Name" v-slot="{ row: team }">
            {{ team.name }}
          </b-table-column>
          <b-table-column label="Besitzer" v-slot="{ row: team }">
            <entity-ref type="user" :entity="team.owner" :name-formatter="(e) => `${e.name} (${e.email})`" />
          </b-table-column>
          <b-table-column label="Mitglieder" v-slot="{ row: team }">
            {{ team.members.length }} Mitglieder
          </b-table-column>
        </b-table>
      </section>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist'
import { makeListMixin } from '@/mixins/editor-mixins.js'
import EntityRef from '@/components/EntityRef'
import SearchQueryInput from '@/components/SearchQueryInput'
const mixinOptions = {
  service: 'teams',
  name: 'teams',
  items: 'teams',
  qid: 'listTeams',
  watch: true,
  searchable: true,
  searchFields: [
    { path: 'name', type: 'String', operation: 'regex', options: 'im', title: 'Team-Name' },
    {
      path: '_id',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Objekt-Id',
      description: 'Es kann nach der Objekt-Id des Teams gesucht werden'
    },
    {
      path: 'owner',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Besitzer',
      description:
        'Es kann nach der Objekt-Id des Besitzers gesucht werden (nicht nach der E-Mail Adresse oder dem Namen)'
    },
    {
      path: 'members.user',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Teammitglied',
      description:
        'Es kann nach der Objekt-Id eines Teammitglieds gesucht werden (nicht nach der E-Mail Adresse oder dem Namen)'
    },
    {
      path: 'subscription',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Abo',
      description: 'Es kann nach der Objekt-Id des Team-Abos gesucht werden'
    }
  ]
}
export default {
  components: {
    EntityRef,
    SearchQueryInput
  },
  mixins: [makeFindMixin(mixinOptions), makeListMixin(mixinOptions)]
}
</script>
