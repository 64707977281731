<template>
  <section class="hero is-secondary is-fullheight is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="column is-4 is-offset-4">
          <div class="login-wrapper">
            <!-- BRANDING -->
            <div class="logo-wrapper">
              <img class="logo" src="@/assets/img/sign-digital-logo_white.svg" />
            </div>

            <!-- LOGIN -->
            <div class="box box-shadow">
              <h1 class="title">Backend-Login</h1>
              <form @submit="onLoginFormSubmit">
                <b-field label="E-Mail Adresse">
                  <b-input
                    name="username"
                    autocomplete="username"
                    type="email"
                    icon="envelope"
                    placeholder="Deine E-Mail Adresse"
                    v-model="email"
                  />
                </b-field>
                <b-field label="Passwort">
                  <b-input
                    id="current-password"
                    name="current-password"
                    autocomplete="current-password"
                    type="password"
                    icon="key"
                    placeholder="Passwort"
                    password-reveal
                    v-model="password"
                  />
                </b-field>
                <b-message type="is-danger" v-if="shownErrorMessage">{{ shownErrorMessage }}</b-message>
                <b-button
                  type="is-secondary"
                  size="is-large"
                  native-type="submit"
                  expanded
                  :disabled="!canSubmit"
                  :loading="isAuthenticatePending"
                  >Einloggen</b-button
                >
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const { mapState } = require('vuex')
export default {
  data () {
    return {
      email: null,
      password: null
    }
  },
  computed: {
    ...mapState('auth', ['isAuthenticatePending', 'errorOnAuthenticate']),
    canSubmit () {
      return this.email && this.email.length && this.password && this.password.length
    },
    shownErrorMessage () {
      if (!this.errorOnAuthenticate) {
        return null
      }
      const error = this.errorOnAuthenticate
      if (error.code && error.code === '401' && error.message === 'No accessToken found in storage') {
        return null
      }
      if (error.message) {
        return error.message
      }
      return error.toSring()
    }
  },
  methods: {
    async onLoginFormSubmit (e) {
      e.preventDefault()
      e.stopPropagation()
      if (!this.canSubmit) {
        return
      }
      await this.$store.dispatch('auth/authenticate', {
        strategy: 'local',
        email: this.email,
        password: this.password
      })
      this.$router.push({ path: 'app' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.logo-wrapper {
  text-align: center;
  margin-bottom: 1em;
  .logo {
    max-width: 250px;
  }
}
.hero .box {
  padding: 2em;
  p,
  .title {
    color: $dark;
  }
  form {
    button {
      margin-bottom: 0.5em;
    }
  }
}
</style>
