<template>
  <div id="subscriptions-view">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="div">
          <div>
            <h1 class="title">Abos</h1>
          </div>
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <search-query-input v-model="query" :search-fields="searchFields" />
        </b-navbar-item>

        <b-navbar-item tag="div">
          <b-button
            type="is-secondary"
            tag="router-link"
            :to="{ name: 'subscription-details', query: { action: 'create' } }"
            >Neues Abo anlegen</b-button
          >
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="container pt-2 pb-6 px-6">
      <b-field grouped label="Filter">
        <b-field label="Status" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.status">
            <option value="">Alle</option>
            <option value="pending-approval">Wartet auf Freigabe</option>
            <option value="planned">Geplante Verlängerung</option>
            <option value="active">Aktiv</option>
            <option value="overdue">Überfällig</option>
            <option value="canceled">Storniert</option>
            <option value="incomplete">Unvollständig</option>
          </b-select>
        </b-field>
        <b-field label="Abo-Typ" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.subscriptionType">
            <option value="">Alle</option>
            <option value="stripe">Stripe</option>
            <option value="manual">Auf Rechnung</option>
          </b-select>
        </b-field>
        <b-field label="Weitere Filter" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.filter">
            <option value="">Kein Filter</option>
            <option value="noInvoiceData">Ohne Rechnungsdaten</option>
          </b-select>
        </b-field>
      </b-field>
      <section>
        <b-table
          hoverable
          class="subscriptions-table"
          :data="subscriptions"
          :paginated="true"
          :backend-pagination="true"
          :loading="isFindSubscriptionsPending"
          :per-page="limit"
          :total="total"
          :current-page="currentPage"
          pagination-position="both"
          pagination-size="is-small"
          @page-change="onPageChange"
          @click="onRowClicked"
        >
          <b-table-column label="Besitzer" field="_id" v-slot="{ row: subscription }">
            <span v-if="subscription.owner">{{ subscription.owner.name }} ({{ subscription.owner.email }})</span>
          </b-table-column>
          <b-table-column label="Infos" v-slot="{ row: subscription }">
            <div class="tags">
              <subscription-type-tag :subscription="subscription" />
              <span class="tag">{{ subscription.licenseObject.title }}</span>
              <span class="tag">Erstellt am {{ subscription.createdAt | dateTimeAt }}</span>
              <span class="tag"
                >{{ subscription.users.length }} von
                {{ subscription.licenseObject.size }}
                {{ 'Nutzer' | pluralize('n', subscription.licenseObject.size) }}
              </span>
              <span class="tag">Läuft bis zum {{ subscription.periodEnd | dateTimeAt }}</span>
            </div>
          </b-table-column>
          <b-table-column label="Status" field="subscriptionStatus" v-slot="{ row: subscription }">
            <div class="tags">
              <span class="tag" :class="$options.filters.subscriptionStatusClass(subscription.status)">{{
                subscription.status | subscriptionStatus
              }}</span>

              <span
                class="tag is-warning"
                v-if="subscription.subscriptionType === 'manual' && subscription.invoice == null"
              >
                Ohne Rechnungsdaten
              </span>
            </div>
          </b-table-column>
        </b-table>
      </section>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist'
import { makeListMixin } from '@/mixins/editor-mixins.js'
import SubscriptionTypeTag from '@custom-media/signdigital-web-shared/src/components/SubscriptionTypeTag'
import SearchQueryInput from '@/components/SearchQueryInput'
const mixinOptions = {
  service: 'subscriptions',
  name: 'subscriptions',
  items: 'subscriptions',
  qid: 'listSubscriptions',
  filterable: true,
  filterableFields: ['subscriptionType'],
  searchable: true,
  searchFields: [
    {
      path: 'owner',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Besitzer',
      description:
        'Es kann nach der Objekt-Id des Besitzers gesucht werden (nicht nach der E-Mail Adresse oder dem Namen)'
    },
    {
      path: 'members',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Mitnutzers',
      description:
        'Es kann nach der Objekt-Id eines Lizenz-Mitnutzers gesucht werden (nicht nach der E-Mail Adresse oder dem Namen)'
    },
    {
      path: '_id',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Objekt-Id',
      description: 'Es kann nach der Objekt-Id des Abos gesucht werden'
    },
    {
      path: 'stripeSubscriptionId',
      type: 'String',
      operation: 'regex',
      options: 'im',
      title: 'Stripe-Abo-Id',
      description: 'Es kann nach der Stripe-Id des zugehörigen Stripe-Abos gesucht werden'
    }
  ],
  watch: true
}
export default {
  mixins: [makeFindMixin(mixinOptions), makeListMixin(mixinOptions)],
  components: {
    SubscriptionTypeTag,
    SearchQueryInput
  }
}
</script>
