<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="div">
        <div>
          <label>{{ pageTitle }}</label>
          <h1 class="title">{{ name }}</h1>
        </div>
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="div">
        <!-- Editing actions -->
        <div class="buttons" v-if="isEditing">
          <b-button @click="$emit('reset')" icon-left="times"> Änderungen verwerfen </b-button>
          <b-button @click="$emit('save')" type="is-secondary" icon-left="check" :disabled="invalid"
            >Speichern</b-button
          >
        </div>
        <!-- Creating -->
        <div class="buttons" v-else-if="isCreating">
          <b-button @click="$emit('close')">Abbrechen</b-button>
          <b-button @click="$emit('save')" type="is-secondary" :disabled="invalid">Erstellen</b-button>
        </div>
        <!-- Details -->
        <div class="buttons" v-else>
          <b-button @click="$emit('close')" icon-left="chevron-left">Zur Übersicht</b-button>
          <b-button v-if="canDuplicate" @click="$emit('duplicate')" icon-left="copy"> Duplizieren </b-button>
          <b-button @click="$emit('edit')" type="is-secondary" icon-left="pencil">Bearbeiten</b-button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  props: {
    title: String,
    createTitle: String,
    editTitle: String,
    name: String,
    isEditing: Boolean,
    isCreating: Boolean,
    canDuplicate: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pageTitle () {
      return this.isCreating ? this.createTitle : this.isEditing ? this.editTitle : this.title
    }
  }
}
</script>
