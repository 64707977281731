<template>
  <b-sidebar position="fixed" type="is-secondary" open :reduce="!expanded" :fullheight="true" :can-cancel="false">
    <section class="top-slot">
      <div class="logo-wrapper">
        <transition name="fade" mode="in-out">
          <img class="logo" :src="logoSrc" alt="SIGN digital - Web App" />
        </transition>
      </div>
      <div class="menu-nav p-1">
        <b-menu class="is-custom-mobile" accordion>
          <!-- MAIN NAV -->
          <b-menu-list>
            <!-- Dashboard -->
            <b-menu-item icon="home" label="Startseite" tag="router-link" :to="{ name: 'dashboard' }">
              <template slot="label">
                <span class="text">Startseite</span>
              </template>
            </b-menu-item>

            <!-- Signs/Categories -->
            <b-menu-item
              icon="sign-language"
              label="Gebärden"
              tag="router-link"
              :to="{ name: 'signs' }"
              :expanded="isSignsMenuExpanded"
              animation="slide"
            >
              <template slot="label">
                <span class="text">Gebärden</span>
              </template>
              <!-- Categories -->
              <b-menu-item label="Kategorien" tag="router-link" :to="{ name: 'categories' }"></b-menu-item>
              <!-- Sign requests -->
              <b-menu-item label="Gebärden-Wünsche" tag="router-link" :to="{ name: 'sign-requests' }"></b-menu-item>
            </b-menu-item>

            <!-- ListTemplates -->
            <b-menu-item icon="list" label="Lernlisten-Vorlagen" tag="router-link" :to="{ name: 'list-templates' }">
              <template slot="label">
                <span class="text">Lernlisten-Vorlagen</span>
              </template>
            </b-menu-item>

            <!-- Users -->
            <b-menu-item
              icon="users"
              label="Benutzer"
              tag="router-link"
              :to="{ name: 'users' }"
              :expanded="isUsersMenuExpanded"
              animation="slide"
            >
              <template slot="label">
                <span class="text">Benutzer</span>
              </template>
              <b-menu-item label="Teams" tag="router-link" :to="{ name: 'teams' }"></b-menu-item>
              <b-menu-item label="Abos" tag="router-link" :to="{ name: 'subscriptions' }"></b-menu-item>
            </b-menu-item>

            <!-- Accounting -->
            <b-menu-item
              icon="book"
              label="Buchhaltung"
              tag="router-link"
              :to="{ name: 'accounting' }"
              :expanded="isAccountingMenuExpanded"
              animation="slide"
            >
              <template slot="label">
                <span class="text">Buchhaltung</span>
              </template>

              <b-menu-item label="Export eGecko" tag="router-link" :to="{ name: 'accounting' }"></b-menu-item>
              <b-menu-item label="Rechnungs-Abos" tag="router-link" :to="{ name: 'invoice-statements' }"></b-menu-item>
            </b-menu-item>

            <!-- PROFILE NAV -->
            <b-dropdown class="user-nav" expanded>
              <b-menu-item icon="user" slot="trigger" slot-scope="{ active }" :active="active">
                <template slot="label">
                  <span>{{ userName }}</span>
                  <b-icon :icon="active ? 'chevron-up' : 'chevron-down'" />
                </template>
              </b-menu-item>
              <b-dropdown-item custom :focusable="false" paddingless>
                <b-menu-list>
                  <b-menu-item label="Abmelden" @click="onLogoutButtonClicked" />
                </b-menu-list>
              </b-dropdown-item>
            </b-dropdown>
          </b-menu-list>
        </b-menu>
      </div>
    </section>
    <section class="bottom-slot">
      <b-menu-list>
        <b-menu-item :icon="expandButtonIcon" label="Einklappen" @click="onExpandButtonClicked()" />
      </b-menu-list>
    </section>
  </b-sidebar>
</template>

<script>
import logo from '@/assets/img/sign-digital-logo_white.svg'
import logoSquare from '@/assets/img/sign-digital-logo_square_white.svg'
export default {
  props: {
    expanded: Boolean
  },
  computed: {
    logoSrc () {
      return this.expanded ? logo : logoSquare
    },
    expandButtonIcon () {
      return this.expanded ? 'arrow-from-right' : 'arrow-from-left'
    },
    isSignsMenuExpanded () {
      return this.$route.name === 'signs' || this.$route.name === 'categories' || this.$route.name === 'sign-requests'
    },
    isUsersMenuExpanded () {
      return this.$route.name === 'users' || this.$route.name === 'subscriptions' || this.$route.name === 'teams'
    },
    isAccountingMenuExpanded () {
      return this.$route.name === 'accounting' || this.$route.name === 'invoice-statements'
    },
    userName () {
      return this.$store.getters['auth/user']?.name
    }
  },
  methods: {
    onExpandButtonClicked () {
      // this.expanded = !this.expanded
      this.$emit('update:expanded', !this.expanded)
    },
    async onLogoutButtonClicked () {
      await this.$store.dispatch('auth/logout')
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/bulma-variables.scss';
.b-sidebar {
  .sidebar-content {
    justify-content: space-between;

    &.is-fullheight {
      overflow: visible;
    }
  }

  .logo-wrapper {
    text-align: center;
    padding: 1em;
    padding-top: 1em;
    margin-bottom: 0;

    img {
      max-height: 60px;
    }
  }

  .menu-list {
    li a {
      &.is-active {
        font-weight: bold;
      }
      .icon {
        margin-right: 0.6em;
      }
    }

    //  USER NAV STYLE
    .user-nav {
      margin-top: 2em;
      border-top: 1px solid lighten($secondary, 15%);
      border-bottom: 1px solid lighten($secondary, 15%);

      li a {
        display: flex;
        justify-content: space-between;
        padding-top: 1em;
        padding-bottom: 1em;

        .icon:last-child {
          font-size: 0.8em;
          margin-right: 0;
        }
      }
    }
  }

  .is-mini {
    li a {
      span:nth-child(2) {
        display: none;
      }
      .icon {
        margin-right: 0;
      }
    }
  }
}
</style>
