<template>
  <div class="sign-details">
    <editor-navbar
      title="Details der Gebärde"
      create-title="Neue Gebärde erstellen"
      edit-title="Gebärde bearbeiten"
      :name="sign.name"
      :is-editing="isEditing"
      :is-creating="isCreating"
      can-duplicate
      @reset="onResetButtonClicked"
      @edit="onEditButtonClicked"
      @close="onCloseButtonClicked"
      @save="onSaveButtonClicked"
      @duplicate="onDuplicateButtonClicked"
    />
    <div class="container p-6">
      <id-tag :object="sign" />

      <feathers-error vuex-store="signs" vuex-action="patch" />
      <feathers-error vuex-store="signs" vuex-action="update" />
      <!-- Properties -->
      <section class="has-background-white p-5">
        <h2 class="subtitle">Eigenschaften</h2>
        <!-- Visibility -->
        <b-field label="Sichtbarkeit">
          <b-switch v-if="isEditing || isCreating" v-model="sign.isPublic">{{
            sign.isPublic ? 'Veröffentlicht' : 'Unsichtbar'
          }}</b-switch>
          <span v-else>{{ sign.isPublic ? 'Veröffentlicht' : 'Unsichtbar' }}</span>
        </b-field>

        <!-- Name -->
        <b-field label="Name">
          <b-input v-if="isEditing || isCreating" v-model="sign.name" />
          <span v-else>{{ sign.name }}</span>
        </b-field>

        <!-- Slug -->
        <b-field label="Slug">
          <b-input v-if="isEditing || isCreating" v-model="sign.slug" />
          <span v-else>{{ sign.slug }}</span>
        </b-field>

        <!-- Keywords -->
        <keyword-input :editable="isEditing || isCreating" v-model="sign.keywords" />

        <!-- Categories -->
        <category-input :editable="isEditing || isCreating" v-model="sign.categories" />
      </section>

      <!-- Media -->
      <section class="has-background-white p-5 mt-5" v-if="sign">
        <h2 class="subtitle">Medien</h2>
        <b-message type="is-info" v-if="isCreating">
          Gebärde zuerst speichern. Im Anschluss können Medien hochgeladen werden.
        </b-message>

        <div v-if="sign != null">
          <sign-file-details
            v-for="fileType in ['signBox', 'signMap', 'signOnly', 'signVideo']"
            :key="fileType"
            :sign="sign"
            :id="signFiles[fileType]"
            :file-type="fileType"
            :disabled="isCreating"
            @changed="onFileChanged"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SignFileDetails from '@/components/SignFileDetails'
import EditorNavbar from '@/components/EditorNavbar'
import CategoryInput from '@/components/CategoryInput'
import KeywordInput from '@/components/KeywordInput'
// import { set } from 'vue'

export default {
  components: {
    SignFileDetails,
    CategoryInput,
    KeywordInput,
    EditorNavbar
  },
  data () {
    return {
      sign: {}
    }
  },
  computed: {
    action () {
      return this.$route.query?.action ?? null
    },
    isEditing () {
      return this.action === 'edit'
    },
    isCreating () {
      return this.action === 'create'
    },
    isDuplicating () {
      return this.$route.query?.duplicate != null
    },
    pageTitle () {
      switch (this.action) {
        case 'edit':
          return 'Gebärde bearbeiten'
        case 'create':
          return 'Neue Gebärde erstellen'
        default:
          return 'Details der Gebärde'
      }
    },
    signFiles () {
      return this.sign?.files ?? {}
    }
  },
  mounted () {
    this.init() // Load or create sign
  },
  methods: {
    async init () {
      const { Sign } = this.$FeathersVuex.api
      if (this.action === 'create') {
        const signIdToDuplicate = this.$route.query?.duplicate
        if (signIdToDuplicate) {
          this.sign = await this.createCopyOfSign(signIdToDuplicate)
        } else {
          this.sign = new Sign()
        }
      } else {
        this.sign = (await Sign.get(this.$route.params.id)).clone()
      }
    },

    async createCopyOfSign (signId) {
      const { Sign } = this.$FeathersVuex.api
      const original = await Sign.get(signId)
      const data = {
        name: `Kopie von ${original.name}`,
        keywords: original.keywords,
        categories: original.categories
      }

      return new Sign(data)
    },

    onDuplicateButtonClicked () {
      this.$router.replace({
        name: 'sign-details',
        query: { action: 'create', duplicate: this.$route.params.id }
      })
      this.init()
    },

    onResetButtonClicked () {
      if (this.isCreating) {
        this.onCloseButtonClicked()
      }
      this.sign.reset()
      this.$router.replace({
        name: 'sign-details',
        params: { id: this.$route.params.id }
      })
    },
    async onSaveButtonClicked () {
      await this.sign.save()
      this.$store.commit('signs/clearError', 'patch')
      this.$store.commit('signs/clearError', 'update')
      this.$store.commit('signs/clearError', 'create')
      this.$router.replace({
        name: 'sign-details',
        params: { id: this.sign._id },
        query: {}
      })
    },
    onCloseButtonClicked () {
      // this.$router.push({ name: 'signs' })
      this.$router.go(-1)
    },
    onEditButtonClicked () {
      this.$router.replace({
        name: 'sign-details',
        params: { id: this.$route.params.id },
        query: { action: 'edit' }
      })
    },
    onFileChanged (fileType, patchedSign) {
      if (this.sign.edge == null) {
        this.sign.edge = {}
      }
      if (this.sign.files == null) {
        this.sign.files = {}
      }
      this.$set(this.sign.edge, fileType, patchedSign.edge[fileType])
      this.$set(this.sign.files, fileType, patchedSign.files[fileType])
    }
  }
}
</script>
