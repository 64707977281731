<template>
  <div class="jump-page-control">
    <b-field label="">
      <p class="control">
        <span class="button is-small is-static">Springe zu Seite</span>
      </p>
      <b-input v-model="internalPage" ref="jumpPageInput" size="is-small" style="width: 4em" />
      <p class="control">
        <b-button size="is-small" type="is-secondary" icon-right="chevron-double-right" @click="onJumpPage" />
      </p>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    skip: Number,
    limit: Number,
    currentPage: Number
  },
  data () {
    return {
      internalPage: 1
    }
  },
  watch: {
    currentPage (newValue) {
      this.internalPage = newValue
    }
  },
  mounted () {
    this.internalPage = this.currentPage
  },
  methods: {
    onJumpPage () {
      const p = Number.parseInt(this.internalPage)
      if (!isNaN(p)) {
        const target = Math.max(Math.min(p, Math.floor(this.total / this.limit) + 1), 1)
        if (!isNaN(target) && target !== this.currentPage) {
          this.$emit('jump', target)
        }
      }
      this.internalPage = this.currentPage
    }
  }
}
</script>
