<template>
  <div id="users-view">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="div">
          <div>
            <h1 class="title">Benutzer</h1>
          </div>
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <search-query-input v-model="query" :search-fields="searchFields" />
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-button type="is-secondary" tag="router-link" :to="{ name: 'user-details', query: { action: 'create' } }"
            >Neuen Benutzer anlegen</b-button
          >
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="container pt-2 pb-6 px-6">
      <b-field grouped label="Filter">
        <b-field label="Rolle" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.role">
            <option value="">Alle</option>
            <option value="customers">Kunde</option>
            <option value="admins">Admin</option>
          </b-select>
        </b-field>
        <b-field label="Typ" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.billingType">
            <option value="">Alle</option>
            <option value="paying">Bezahlender Kunde</option>
            <option value="free">Kostenlos</option>
            <option value="member">Teammitglied</option>
          </b-select>
        </b-field>
        <b-field label="Status" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.isDeactivated">
            <option value="">Alle</option>
            <option :value="false">Aktiv</option>
            <option :value="true">Deaktiviert</option>
          </b-select>
        </b-field>
        <b-field label="E-Mail verifiziert" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.isEmailVerified">
            <option value="">Alle</option>
            <option :value="true">Ja</option>
            <option :value="false">Nein</option>
          </b-select>
        </b-field>
        <b-field label="Berechtigung" label-position="on-border">
          <b-select placeholder="Filter auswählen" v-model="filters.specialPermissions">
            <option value="">Alle</option>
            <option value="multiplicator">Referent</option>
          </b-select>
        </b-field>
      </b-field>
      <b-table
        striped
        hoverable
        :data="users"
        :paginated="true"
        :backend-pagination="true"
        :loading="isFindUsersPending"
        :per-page="limit"
        :total="total"
        :current-page="currentPage"
        pagination-position="both"
        pagination-size="is-small"
        @page-change="onPageChange"
        @click="onRowClicked"
      >
        <!-- Name column -->
        <b-table-column label="E-Mail" field="email" v-slot="{ row }">
          <span :class="{ 'has-text-danger': row.isDeactivated }">{{ row.email }}</span>
        </b-table-column>
        <b-table-column label="Name" field="name" v-slot="{ row }">
          <span :class="{ 'has-text-danger': row.isDeactivated }">{{ row.name }}</span>
        </b-table-column>
        <b-table-column label="Rolle" field="role" v-slot="{ row }">
          <span :class="{ 'has-text-danger': row.isDeactivated }">{{ row.role }}</span>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>
<script>
import { makeFindMixin } from 'feathers-vuex/dist'
import { makeListMixin } from '@/mixins/editor-mixins.js'
import SearchQueryInput from '@/components/SearchQueryInput'
const mixinOptions = {
  service: 'users',
  name: 'users',
  items: 'users',
  qid: 'listUsers',
  watch: true,
  searchable: true,
  searchFields: [
    { path: 'email', type: 'String', operation: 'regex', options: 'im', title: 'E-Mail' },
    { path: 'name', type: 'String', operation: 'regex', options: 'im', title: 'Name' },
    {
      path: '_id',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Objekt-Id',
      description: 'Es kann nach der Objekt-Id des Benutzers gesucht werden'
    },
    {
      path: 'team',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Team',
      description: 'Es kann nach der Objekt-Id des Teams gesucht werden (nicht nach dem Namen des Teams)'
    },
    {
      path: 'subscription',
      type: 'ObjectId',
      operation: 'eq',
      title: 'Abo',
      description: 'Es kann nach der Objekt-Id des Abos gesucht werden'
    }
  ],
  filterable: true,
  filterableFields: ['role', 'specialPermissions', 'isDeactivated', 'billingType', 'isEmailVerified']
}
export default {
  components: {
    SearchQueryInput
  },
  mixins: [makeFindMixin(mixinOptions), makeListMixin(mixinOptions)],
  computed: {},
  methods: {}
}
</script>
