<template>
  <div class="user-details">
    <validation-observer v-slot="{ invalid }">
      <editor-navbar
        title="Details des Benutzers"
        create-title="Neuen Benutzer erstellen"
        edit-title="Benutzer bearbeiten"
        :name="user.name"
        :is-editing="isEditing"
        :is-creating="isCreating"
        @reset="onResetButtonClicked"
        @edit="onEditButtonClicked"
        @close="onCloseButtonClicked"
        @save="onSaveButtonClicked"
        :invalid="invalid"
      />

      <b-loading :active="isLoading" />

      <div class="container p-6">
        <b-message v-if="isCreating && user.isEmailVerified && setPassword && user.password" type="is-warning">
          Der Benutzer bekommt keine E-Mail, da seine E-Mail Adresse als verifiziert eingestellt und bereits ein
          Passwort gesetzt wurde. Bitte informiere den Nutzer ggf. selbst, dass du ein SIGNdigital Konto für ihn
          angelegt hast.
        </b-message>

        <div class="has-background-white p-5" v-if="user">
          <div class="level">
            <div class="level-left">
              <h2 class="subtitle">Benutzer-Eigenschaften</h2>
            </div>
            <div class="level-right">
              <b-taglist attached v-if="user._id">
                <b-tag type="is-secondary">ID</b-tag>
                <b-tag>{{ user._id }}</b-tag>
              </b-taglist>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <!-- NAME -->
              <validation-provider name="Name" rules="required" immediate v-slot="{ errors }">
                <b-field label="Name" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
                  <b-input type="text" v-model="user.name" :disabled="!isEditable" expanded />
                </b-field>
              </validation-provider>

              <!-- MAIL -->
              <validation-provider name="E-Mail Adresse" rules="required|email" immediate v-slot="{ errors }">
                <b-field label="E-Mail Adresse" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
                  <b-input type="email" v-model="user.email" :disabled="!isEditable" expanded />
                </b-field>
              </validation-provider>
            </div>

            <div class="column">
              <!-- ROLE -->
              <validation-provider name="Rolle" rules="required" v-slot="{ errors }" immediate>
                <b-field label="Rolle" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
                  <b-select :disabled="!isEditable" v-model="user.role" expanded>
                    <option value="admins">Administrator</option>
                    <option value="customers">Kunde</option>
                  </b-select>
                </b-field>
              </validation-provider>

              <!-- BILLING TYPE -->
              <validation-provider name="Abrechnungsart" rules="required" v-slot="{ errors }" immediate>
                <b-field label="Abrechnungsart" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
                  <b-select :disabled="!isEditable" v-model="user.billingType" expanded>
                    <option value="free">Kostenlos</option>
                    <option value="paying">Normal bezahlender Kunde</option>
                    <option value="member">Mitnutzer von Team-Lizenz</option>
                  </b-select>
                </b-field>
              </validation-provider>
            </div>
          </div>

          <!-- EMAIL VERIFICATION -->
          <b-field label="E-Mail Adresse bestätigt">
            <b-switch v-model="user.isEmailVerified" :disabled="!isEditable" expanded
              >E-Mail Adresse verifiziert</b-switch
            >
          </b-field>
          <b-message v-if="isCreating && !user.isEmailVerified">
            Der Benutzer erhält eine E-Mail, in der er darum gebeten wird seine E-Mail Adresse zu bestätigen.
          </b-message>

          <!-- CHANGE/SET PASSWORD -->
          <b-field label="Passwort festlegen" v-if="isCreating">
            <b-switch v-model="setPassword"> Passwort jetzt vergeben </b-switch>
          </b-field>
          <b-message v-if="isCreating && !setPassword">
            Der Benutzer erhält eine E-Mail mit einem Link, über den er sein Passwort festlegen kann.
          </b-message>
          <b-field label="Passwort" v-if="isCreating && setPassword">
            <b-input type="password" placeholder="Passwort" password-reveal v-model="user.password" />
          </b-field>

          <!-- SPECIAL PERMISSIONS -->
          <b-field label="Besondere Berechtigungen">
            <special-permissions-input :disabled="!isEditable" v-model="user.specialPermissions" />
          </b-field>

          <!-- SUBSCRIPTION -->
          <b-field label="Abo">
            <entity-ref
              v-if="user.subscription"
              :entity="user.subscription"
              type="subscription"
              resolve
              :name-formatter="(e) => e.licenseObject.title"
            />
            <b-message v-if="isCreating && user.role === 'customers'"
              >Zuerst muss der Benutzer angelegt werden, anschließend kann ein Abo zugewiesen werden.</b-message
            >
            <p v-if="!isCreating && user.billingType === 'free'" class="has-text-grey">
              Einem Benutzer mit der Abrechnungsart „Kostenlos” sollte kein Abo zugewiesen sein.
            </p>
            <b-button
              v-if="!isCreating && !isEditing && !user.subscription && user.billingType === 'paying'"
              tag="router-link"
              :to="{
                name: 'subscription-details',
                query: { action: 'create', userId: user._id }
              }"
              >Abo für diesen Nutzer erstellen</b-button
            >
          </b-field>

          <!-- TEAM -->
          <b-field label="Team">
            <p v-if="!user.team" class="has-text-grey">Keinem Team zugewiesen</p>
            <entity-ref v-else :entity="user.team" type="team" resolve :name-formatter="(e) => e.name" />
          </b-field>

          <b-field label="Nutzer deaktivieren">
            <b-switch v-model="user.isDeactivated" :disabled="!isEditable" type="is-danger"
              >Zugang deaktivieren</b-switch
            >
            <p v-if="user.isDeactivated" class="has-text-danger">
              Der Nutzer hat keinen Zugang zu SIGNdigital mehr. Bitte denke daran ihn ggf. zu informieren und sein Abo
              zu stornieren
            </p>
          </b-field>
        </div>

        <div class="has-background-white p-5 mt-5" v-if="user && user.role === 'customers'">
          <h2 class="subtitle">Kundendaten für Rechnungsadresse</h2>
          <!-- CUSTOMER DETAILS -->
          <customer-details
            v-if="user"
            ref="customerDetails"
            :customer-id="user.entity"
            :creating="isCreating"
            :editing="isEditing"
          />
        </div>

        <div class="has-background-white p-5 mt-5" v-if="user && user.role === 'admins'">
          <h2 class="subtitle">Admin-Infos</h2>
          <!-- ADMIN DETAILS -->
          <admin-details
            v-if="user"
            ref="adminDetails"
            :admin-id="user.entity"
            :creating="isCreating"
            :editing="isEditing"
          />
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import EditorNavbar from '@/components/EditorNavbar'
import EntityRef from '@/components/EntityRef'
import SpecialPermissionsInput from '@/components/SpecialPermissionsInput'
import { makeEditorMixin } from '@/mixins/editor-mixins.js'
import CustomerDetails from '@/components/CustomerDetails'
import AdminDetails from '@/components/AdminDetails'
import { omitBy, isNil } from 'lodash'
export default {
  mixins: [makeEditorMixin({ service: 'users' })],
  components: {
    EditorNavbar,
    EntityRef,
    SpecialPermissionsInput,
    CustomerDetails,
    AdminDetails
  },
  data () {
    return {
      setPassword: false
    }
  },
  created () {
    console.log('UserDetails created', this)
  },
  mounted () {
    console.log('UserDetails mounted', this)
    this.$on('after-create', () => {
      this.setPassword = false
      delete this.user.password
      if (typeof this.user.entity !== 'string') {
        this.user.entity = this.user.entity._id
      }

      // Init entity editor
      if (this.user.role === 'customers') {
        this.$refs.customerDetails.init()
      } else if (this.user.role === 'admins') {
        this.$refs.adminDetails.init()
      }
    })

    this.$on('before-reset', () => {
      if (this.$refs.customerDetails) {
        this.$refs.customerDetails.onResetButtonClicked()
      }
      if (this.$refs.adminDetails) {
        this.$refs.adminDetails.onResetButtonClicked()
      }
    })
  },
  methods: {
    async prepareUserSave (user) {
      if (this.isCreating) {
        // Before save, insert entity data in <entityRole>Data property
        if (this.user.role === 'customers') {
          user.customersData = omitBy(this.$refs.customerDetails.customer, isNil)
        } else if (this.user.role === 'admins') {
          user.adminsData = omitBy(this.$refs.adminDetails.admin, isNil)
        }
      } else if (this.isEditing) {
        // When saving, also save the entity object
        if (this.user.role === 'customers') {
          this.$refs.customerDetails.customer.save()
        } else if (this.user.role === 'admins') {
          this.$refs.adminDetails.admin.save()
        }
      }
      return user
    }
  }
}
</script>
