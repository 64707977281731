<template>
  <div class="app-layout">
    <!-- SIDEBAR -->
    <sidebar :expanded.sync="isExpanded" />
    <!-- END: SIDEBAR -->

    <!-- APP PAGE -->
    <div class="app-page" :class="sidebarClass">
      <!-- <navbar></navbar> -->
      <router-view></router-view>
    </div>
    <!-- END: APP PAGE -->
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
export default {
  components: {
    Sidebar
  },
  data () {
    return {
      isExpanded: true
    }
  },
  computed: {
    sidebarClass () {
      return this.isExpanded ? 'sidebar-expanded' : 'sidebar-mini'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/bulma-variables.scss';

.app-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
}

.app-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;

  &.sidebar-expanded {
    padding-left: $sidebar-width;
  }
  &.sidebar-mini {
    padding-left: $sidebar-mobile-width;
  }
}
</style>
