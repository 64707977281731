<template>
  <div class="file-details p-2">
    <b-loading :active="isGetPending || isProcessing" :is-full-page="false" />

    <div class="level">
      <div class="level-left">
        <h3 class="title">
          {{ fileType | signFileType }}
        </h3>
      </div>
      <div class="level-right">
        <div class="buttons">
          <b-button v-if="!isEditing" @click="onReprocessButtonClicked" :disabled="id == null" :loading="isReprocessing"
            >{{ fileType | signFileType }} neu verarbeiten</b-button
          >
          <b-button v-if="!isEditing" @click="onEditButtonClicked" :disabled="disabled"
            >{{ fileType | signFileType }} hochladen</b-button
          >
          <b-button v-if="isEditing && isModified" type="is-danger" @click="onResetButtonClicked"
            >Änderung verwerfen</b-button
          >
          <b-button v-if="isEditing && isModified" type="is-primary" @click="onSaveButtonClicked">Speichern</b-button>
        </div>
      </div>
    </div>

    <!-- Empty state -->
    <b-message type="is-warning" v-if="id == null && file == null">
      Noch keine {{ fileType | signFileType }}-Datei hochgeladen.
    </b-message>

    <!-- File upload -->
    <div class="edit mb-5" v-if="isEditing">
      <b-field>
        <file-upload
          expanded
          @uploaded="onFileUploaded"
          :processing="isProcessing"
          :source="sign._id"
          category="signs"
          :purpose="fileType"
          acl="public"
        />
      </b-field>
    </div>

    <!-- Existing file details -->
    <div class="columns" v-if="file">
      <div class="column info">
        <label>Eigenschaften:</label>
        <id-tag class="mt-2" :object="file" />
        <table class="table">
          <tbody>
            <tr>
              <th>Status</th>
              <td>
                <b-tag>{{ file.status }}</b-tag>
              </td>
            </tr>

            <tr>
              <th>Erstellt</th>
              <td>
                <b-tag>{{ file.createdAt | dateTimeAt }}</b-tag>
              </td>
            </tr>

            <tr>
              <th>Zuletzt geändert</th>
              <td>
                <b-tag>{{ file.updatedAt | dateTimeAt }}</b-tag>
              </td>
            </tr>
          </tbody>
        </table>

        <label>Automatisch erzeugte Datei-Produkte:</label>
        <b-message v-if="file.status === 'created'" type="is-warning"
          >Die Datei wurde verändert und wird verarbeitet, sobald die Änderungen gespeichert sind.</b-message
        >
        <table class="table products" v-if="file.status === 'processed'">
          <tbody>
            <tr :key="product._id" v-for="product in file.products">
              <td>
                <video-component
                  v-if="fileType === 'signVideo'"
                  :src="sources[product.variant]"
                  :is-playing="false"
                  controls
                />
                <image-component v-else :src="sources[product.variant]" :file-type="fileType" />
              </td>
              <td>
                <b-taglist>
                  <b-tag>{{ product._id }}</b-tag>
                  <b-tag icon="folder-tree">{{ product.variant }}</b-tag>
                  <b-tag icon="file">{{ product.fileType }}</b-tag>
                  <b-tag icon="lock" v-if="product.acl">{{ product.acl }}</b-tag>
                </b-taglist>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column details">
        <!-- Content  -->
        <div class="preview">
          <video-component
            v-if="sources.original != null && fileType === 'signVideo'"
            :src="sources.original"
            :is-playing="false"
            controls
          />
          <image-component v-else-if="sources.original != null" :src="sources.original" :file-type="fileType" />
        </div>
      </div>
    </div>
    <!-- <pre>{{ file }}</pre>
      <div class="tags" v-if="file">
        <b-tag>{{ file.category }}</b-tag>
        <b-tag>{{ file.createdAt | dateTimeAt }}</b-tag>
        <b-tag>{{ file.updatedAt | dateTimeAt }}</b-tag>
      </div> -->
  </div>
</template>

<script>
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent'
import VideoComponent from '@custom-media/signdigital-web-shared/src/components/VideoComponent'

import FileUpload from '@custom-media/signdigital-web-shared/src/components/FileUpload'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    ImageComponent,
    // VideoComponent,
    VideoComponent,
    FileUpload
  },
  props: {
    id: String,
    sign: {
      type: Object
    },
    fileType: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      file: null,
      isProcessing: false,
      isEditing: false,
      isModified: false,
      isReprocessing: false,
      sources: {}
    }
  },
  computed: {
    ...mapState('files', ['isGetPending', 'errorOnGet'])
  },
  mounted () {
    // this.resolve()
    this.init()
  },
  watch: {
    id () {
      console.log('file id changed')
      this.init()
    }
  },
  methods: {
    ...mapActions('files', { getFile: 'get' }),
    async init () {
      this.isEditing = false
      this.isModified = false
      this.file = null
      this.isProcessing = false
      if (this.id == null) {
        return
      }
      try {
        this.file = await this.getFile(this.id)
        this.sources = await this.getSources()
      } catch (error) {
        console.error(error)
      }
    },
    async getSources () {
      // Get original src
      let variants = ['original']
      if (this.file?.status === 'processed') {
        variants = [...variants, ...this.file.products.map((p) => p.variant)]
      }
      const edgeKeys = variants.map((v) => this.$resolver.getVariantKey(this.file, v))
      console.log(edgeKeys)
      const presignedUrls = await this.$store.dispatch('cargo/getMany', edgeKeys)
      return variants.reduce((acc, v, i) => ({ ...acc, [v]: presignedUrls[i] }), {})
    },
    async onFileUploaded (file) {
      // NOTE: Has created the file and uploaded original. Will process once assigned to the sign.
      this.file = file
      this.isModified = true
      this.sources = await this.getSources()
    },
    onEditButtonClicked () {
      this.isEditing = true
    },
    async onReprocessButtonClicked () {
      this.isReprocessing = true
      this.file.status = 'processing'
      await this.sign.patch({ data: {}, query: { reprocess: [this.fileType] } })
      this.file.status = 'processed'
      this.isReprocessing = false
    },
    onResetButtonClicked () {
      if (this.isModified) {
        this.file.remove()
      }
      this.init()
    },
    async onSaveButtonClicked () {
      const signFileKey = `files.${this.fileType}`
      // const patchedFile = await this.sign.patch({ data: { $set: { [key]: file._id } } })
      this.isProcessing = true
      try {
        const patchedSign = await this.sign.patch({ data: { $set: { [signFileKey]: this.file._id } } })
        console.log('Patched sign', patchedSign)
        this.$emit('changed', this.fileType, patchedSign)
      } catch (error) {
        console.error(error)
      } finally {
        this.isProcessing = false
      }

      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.file-details {
  position: relative;
}
h3.title {
  font-size: 1.2em;
}
.image-component {
  max-width: 300px;
}
.details {
  .image-component {
    margin: 0 auto;
  }
}
.products {
  .image-component {
    width: 100px;
  }
  .video-component {
    min-width: 250px;
    max-width: 350px;
  }
}
</style>
