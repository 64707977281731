<template>
  <div class="accounting-view">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="div">
          <div>
            <h1 class="title">Abrechnung der Rechnungs-Abos</h1>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="container p-6">
      <div class="has-background-white p-5">
        <b-field label="Festen Zeitraum auswählen">
          <b-select @input="selectFixedRange($event)" v-model="fixedRange">
            <option v-for="option in fixedRanges" :key="option.name" :value="option">{{ option.name }}</option>
          </b-select>
        </b-field>
        <p>oder</p>
        <b-field label="Eigenen Zeitraum festlegen">
          <b-datepicker
            placeholder="Zeitraum auswählen"
            v-model="manualRange"
            range
            @input="onDateRangeChanged"
          ></b-datepicker>
        </b-field>

        <b-button type="is-primary" :disabled="!manualRangeUTC" @click="findSubscriptions()">
          Rechnungs-Abos auflisten
        </b-button>
      </div>
    </div>

    <div class="container p-6" v-if="subscriptions">
      <div class="has-background-white p-5">
        <h4>Auswertung</h4>
        <b-table :data="subscriptions" @click="openSubscriptionDetails($event)">
          <template>
            <b-table-column field="_id" label="ID" v-slot="props">
              <pre class="is-size-7 p-1" @click.stop.prevent style="white-space: break-spaces; max-width: 250px">{{
                addressLines(props.row.customer)
                  .map((l) => l.content)
                  .join('\n')
              }}</pre>
              <span class="is-size-7">{{ props.row._id }}</span>
            </b-table-column>
            <b-table-column field="license" label="Lizenz" v-slot="props">{{ props.row.license }}</b-table-column>
            <b-table-column field="createdAt" label="Datum" v-slot="props">
              <span>{{ props.row.createdAt | date }}</span>
            </b-table-column>
            <b-table-column field="invoice.net" label="Netto" v-slot="props">
              <span v-if="props.row.invoice">{{ props.row.invoice.net | price }}</span>
            </b-table-column>

            <b-table-column field="invoice.vatRate" label="USt.-Satz" v-slot="props">
              <span v-if="props.row.invoice">{{ props.row.invoice.vatRate }}</span>
            </b-table-column>
            <b-table-column field="invoice.vat" label="USt." v-slot="props">
              <span v-if="props.row.invoice">{{ props.row.invoice.vat | price }}</span>
            </b-table-column>
            <b-table-column field="invoice.gross" label="Brutto" v-slot="props">
              <span v-if="props.row.invoice">{{ props.row.invoice.gross | price }}</span>
            </b-table-column>
          </template>
          <template #footer>
            <th></th>
            <th></th>
            <th></th>
            <th>{{ netSum | price }}</th>
            <th></th>
            <th>{{ vatSum | price }}</th>
            <th>{{ grossSum | price }}</th>
          </template>
        </b-table>

        <b-button v-if="subscriptions" @click="createCSV()">Download</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { apiMixin } from '@/mixins/api-mixin'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import utc from 'dayjs/plugin/utc'
dayjs.extend(quarterOfYear)
dayjs.extend(utc)

export default {
  mixins: [apiMixin],
  components: {},
  data () {
    return {
      fixedRange: null,
      manualRange: null,
      manualRangeUTC: null,
      subscriptions: null
    }
  },
  computed: {
    fixedRanges () {
      const now = dayjs().utc(true).endOf('day')
      const start = dayjs('2021-10-01').utc(true)
      // const startQuarter = start.quarter()
      let current = start.startOf('quarter')
      const entries = []
      while (current < now) {
        const year = current.year()
        const q = current.quarter()
        entries.push({
          name: `${q}. Quartal ${year}`,
          start: current.startOf('quarter').toDate(),
          end: current.endOf('quarter').toDate()
        })
        if (q % 2 === 0) {
          // entries.push(`${q / 2}. Halbjahr ${year}`)
          const semester = q / 2
          entries.push({
            name: `${semester}. Halbjahr ${year}`,
            start: dayjs(current.startOf('year'))
              .add((semester - 1) * 6, 'month')
              .toDate(),
            end: dayjs(current.startOf('year'))
              .add(semester * 6, 'month')
              .toDate()
          })
        }
        if (q % 4 === 0) {
          entries.push({
            name: `Gesamtes Jahr ${year}`,
            start: current.startOf('year').toDate(),
            end: current.endOf('year').toDate()
          })
        }
        current = current.add(1, 'quarter')
      }
      if (now.quarter() !== 4) {
        entries.push({
          name: `Gesamtes Jahr ${now.year()}`,
          start: now.startOf('year').toDate(),
          end: now.endOf('year').toDate()
        })
      }
      return entries.reverse()
    },
    netSum () {
      return this.subscriptions.reduce((acc, s) => acc + (s.invoice?.net ?? 0), 0)
    },
    vatSum () {
      return this.subscriptions.reduce((acc, s) => acc + (s.invoice?.vat ?? 0), 0)
    },
    grossSum () {
      return this.subscriptions.reduce((acc, s) => acc + (s.invoice?.gross ?? 0), 0)
    }
  },
  methods: {
    addressLines (customer) {
      if (!customer) {
        return []
      }
      const { customerType, name, company, additional, street, number, postcode, city, vatId, countryCode } = customer
      const addressLines = []
      if (customerType === 'person') {
        addressLines.push({ key: 'name', content: name })
        if (additional) {
          addressLines.push({ key: 'additional', content: additional })
        }
        addressLines.push({ key: 'addressLine1', content: `${street} ${number}` })
        addressLines.push({ key: 'addressLine2', content: `${postcode} ${city}` })
        addressLines.push({ key: 'country', content: `${countryCode}` })
      } else {
        addressLines.push({ key: 'company', content: company })
        if (name) {
          addressLines.push({ key: 'name', content: name })
        }
        addressLines.push({ key: 'addressLine1', content: `${street} ${number}` })
        addressLines.push({ key: 'addressLine2', content: `${postcode} ${city}` })
        addressLines.push({ key: 'country', content: `${countryCode}` })
        if (vatId) {
          addressLines.push({ key: 'vatId', content: vatId })
        }
      }
      return addressLines
    },
    selectFixedRange (value) {
      this.manualRange = [value.start, value.end]
      this.manualRangeUTC = [value.start, value.end]
    },
    onDateRangeChanged () {
      this.manualRangeUTC = [
        dayjs(this.manualRange[0]).utc(true).startOf('day').toDate(),
        dayjs(this.manualRange[1]).utc(true).endOf('day').toDate()
      ]
      this.fixedRange = null
    },
    async findSubscriptions () {
      this.subscriptions = null
      const res = await this.$store.dispatch('subscriptions/find', {
        query: {
          status: { $ne: 'pending-approval' },
          subscriptionType: 'manual',
          createdAt: {
            $gte: this.manualRangeUTC[0],
            $lte: this.manualRangeUTC[1]
          },
          shallow: true,
          accounting: true,
          paginate: false
        }
      })
      this.subscriptions = res
    },
    openSubscriptionDetails (row) {
      const route = this.$router.resolve({
        name: 'subscription-details',
        params: { id: row._id }
      })
      window.open(route.href, '_blank')
    },

    createCSV () {
      let file = 'ID;Adresse;Lizenz;Datum;Netto;USt.-Satz;USt.;Brutto;URL'

      for (const s of this.subscriptions) {
        const { href } = this.$router.resolve({
          name: 'subscription-details',
          params: { id: s._id }
        })
        const url = process.env.VUE_APP_BACKEND_HOST + href
        const address = this.addressLines(s.customer)
          .map((l) => l.content)
          .join('\n')
        file += `\n${s._id};"${address}";${s.license};${s.createdAt.toLocaleDateString('de-DE')};${s.invoice?.net?.toLocaleString('de-DE') ?? ''};${s.invoice?.vatRate?.toLocaleString('de-DE') ?? ''};${s.invoice?.vat?.toLocaleString('de-DE') ?? ''};${s.invoice?.gross?.toLocaleString('de-DE') ?? ''};${url}`
      }
      const blob = new Blob([file], { type: 'text/csv' })
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${this.fixedRange?.name ?? 'Rechnungsexport'}.csv`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    }
  }
}
</script>
