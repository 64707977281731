<template>
  <div class="category-input">
    <b-loading :active="areCategoriesLoading" :is-full-page="false" />
    <b-field label="Kategorien">
      <b-taginput
        v-if="editable"
        :value="internalSelection"
        autocomplete
        :data="filteredCategories"
        @typing="getFilteredCategories"
        @input="onInternalSelectionChanged"
        ellipsis
        icon="folder"
        placeholder="Kategorie hinzufügen"
        aria-close-label="Stichwort entfernen"
        field="name"
      >
      </b-taginput>
      <div v-else class="tags">
        <div class="tag" :key="category._id" v-for="category in internalSelection">
          {{ category.name }}
        </div>
      </div>
    </b-field>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      internalSelection: null,
      filteredCategories: null
    }
  },
  async created () {
    await this.findCategories()
    this.filteredCategories = [...this.categories]
    this.updateInternalSelection()
  },
  watch: {
    value () {
      this.updateInternalSelection()
    }
  },
  computed: {
    ...mapState('categories', { areCategoriesLoading: 'isFindPending' }),
    ...mapGetters('categories', { categoriesList: 'list' }),
    categories () {
      return this.categoriesList.filter((c) => c.children == null)
    }
  },
  // categories: {
  //   get () {
  //     return this.internalSelection
  //   },
  //   set (newCategories) {
  //     this.$emit('update:value', this.internalSelection.map(s => s._id))
  //   }
  // },
  methods: {
    ...mapActions('categories', { findCategories: 'find' }),
    updateInternalSelection () {
      this.internalSelection = this.value.map((categoryId) => this.categories.find((c) => c._id === categoryId))
    },
    onInternalSelectionChanged (categories) {
      this.internalSelection = categories.filter((c) => c != null)
      this.$emit(
        'input',
        this.internalSelection.map((c) => c._id)
      )
    },
    getFilteredCategories (text) {
      this.filteredCategories = this.categories.filter((category) => {
        return category.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.category-input {
  position: relative;
}
</style>
