<template>
  <div class="team-details">
    <editor-navbar
      title="Details des Teams"
      create-title="Neues Teams erstellen"
      edit-title="Team bearbeiten"
      :name="team.name"
      :is-editing="isEditing"
      :is-creating="isCreating"
      @reset="onResetButtonClicked"
      @edit="onEditButtonClicked"
      @close="onCloseButtonClicked"
      @save="onSaveButtonClicked"
    />

    <div class="container p-6">
      <div class="has-background-white p-5" v-if="team">
        <!-- Team name -->
        <validation-provider name="Name" immediate v-slot="{ errors }" slim>
          <b-field label="Name" :message="errors[0]" :type="{ 'is-danger': errors.length > 0 }">
            <b-input type="text" v-model="team.name" :disabled="!isEditable" />
          </b-field>
        </validation-provider>

        <b-field label="Beschreibung">
          <b-input type="textarea" v-model="team.description" :disabled="!isEditable" />
        </b-field>

        <b-field label="Besitzer">
          <entity-ref
            v-if="!isEditable"
            type="user"
            :entity="team.owner"
            resolve
            :name-formatter="(e) => `${e.name} (${e.email})`"
          />
        </b-field>

        <b-field label="Abo">
          <entity-ref
            v-if="team.subscription"
            :entity="team.subscription"
            type="subscription"
            resolve
            :name-formatter="(e) => e.licenseObject.title"
          />
        </b-field>

        <b-field label="Teammitglieder">
          <ul>
            <li :key="member._id" v-for="member in team.members" class="is-flex">
              <entity-ref
                v-if="!isEditable"
                type="user"
                :entity="member.user"
                resolve
                :name-formatter="(e) => `${e.name} (${e.email})`"
              />
              <span class="ml-4">{{ member.role }}</span>
            </li>
          </ul>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import EditorNavbar from '@/components/EditorNavbar'
import EntityRef from '@/components/EntityRef'
import { makeEditorMixin } from '@/mixins/editor-mixins.js'
export default {
  mixins: [makeEditorMixin({ service: 'teams' })],
  components: {
    EditorNavbar,
    EntityRef
  }
}
</script>
