<template>
  <div class="keyword-input">
    <b-field label="Stichwörter">
      <b-taginput
        v-if="editable"
        :value="value"
        @input="$emit('input', $event)"
        ellipsis
        icon="tag"
        placeholder="Stichwort hinzufügen"
        aria-close-label="Stichwort entfernen"
      >
      </b-taginput>
      <div v-else class="tags">
        <div class="tag" :key="keyword" v-for="keyword in value">{{ keyword }}</div>
      </div>
    </b-field>
  </div>
</template>

<script>
// IMPROVEMENT: Autocomplete to already used keywords
export default {
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>
