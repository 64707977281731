<template>
  <div>
    <p v-if="editable && applicableProposals.length && size !== 'is-small'" class="mb-4">
      <span>Unten stehen Vorschläge</span>
      <b-icon icon="magic" />
      <span>
        basierend auf der Lizenz, den Kundendaten oder aufgrund vorheriger Werte. Die Basis aufgrund der eine Empfehlung
        gegeben wird, ist in Klammern angegeben.
      </span>
    </p>
    <b-button
      class="mb-4"
      icon-left="chevron-double-left"
      icon-right="magic"
      @click="onApplyProposalsButtonClicked()"
      v-if="editable && applicableProposals.length"
      :size="size"
    >
      Alle Vorschläge übernehmen
    </b-button>
    <div class="field" :class="editable ? '' : 'is-grouped is-grouped-multiline'">
      <b-field label="Netto-Preis (€)" grouped>
        <b-input type="number" step=".01" v-model.number="netProxy" v-if="editable" :size="size" />
        <span v-else>{{ data.net | price }}</span>
        <b-button
          icon-left="chevron-left"
          icon-right="magic"
          v-if="editable && applicableProposals.includes('net') && hasProposedNet"
          @click="applyProposedNet()"
          :size="size"
        >
          {{ proposedNet | price }} ({{ netProposalBasedOn }})
        </b-button>
      </b-field>
      <b-field label="USt.- Satz (%)" grouped>
        <b-input type="number" step=".01" v-model.number="vatRatePercent" v-if="editable" :size="size" />
        <span v-else>{{ vatRatePercent }} %</span>
        <b-button
          icon-left="chevron-left"
          icon-right="magic"
          v-if="editable && applicableProposals.includes('vatRate')"
          @click="applyProposedVatRate()"
          :size="size"
        >
          {{ Math.round(proposedVatRate * 100 * 100) / 100 }} % (Land & USt.-ID)
        </b-button>
      </b-field>
      <b-field label="USt. (€)" grouped>
        <b-input type="number" step=".01" v-model.number="vatProxy" v-if="editable" :size="size" />
        <span v-else>{{ data.vat | price }}</span>
        <b-button
          icon-left="chevron-left"
          icon-right="magic"
          v-if="editable && applicableProposals.includes('vat')"
          :size="size"
          @click="applyProposedVat()"
        >
          {{ proposedVat | price }} ({{ vatProposalBasedOn }})
        </b-button>
      </b-field>
      <b-field label="Brutto-Preis (€)" grouped>
        <b-input type="number" step=".01" v-model.number="grossProxy" v-if="editable" :size="size" />
        <span v-else>{{ data.gross | price }}</span>
        <b-button
          icon-left="chevron-left"
          icon-right="magic"
          v-if="editable && applicableProposals.includes('gross')"
          @click="applyProposedGross()"
          :size="size"
        >
          {{ proposedGross | price }} (Netto-Preis & USt.-Satz)
        </b-button>
      </b-field>

      <b-message v-if="!isInvoiceDataValid" type="is-warning">
        <p>Rechnungsdaten ungültig oder unvollständig:</p>

        <pre>
          Netto   {{ this.data.net | price }}
          USt.    {{ this.data.vat | price }}
          ---------------------
          Brutto  {{ (this.data.net + this.data.vat) | price }} ({{ this.data.gross | price }} gesetzt)
        </pre>
      </b-message>
    </div>
  </div>
</template>

<script>
import * as countryDefinitions from '@custom-media/signdigital-lib/src/constants/country-definitions'
export default {
  props: {
    value: Object,
    customer: Object,
    license: Object,
    editable: {
      type: Boolean,
      default: true
    },
    size: {
      default: 'is-default',
      type: String
    }
  },
  data () {
    return {
      data: {
        net: null,
        vatRate: null,
        vat: null,
        gross: null
      }
    }
  },
  computed: {
    isInvoiceDataValid () {
      return (
        Math.round(((this.data.net ?? 0) + (this.data.vat ?? 0)) * 100.0) / 100.0 ===
        Math.round((this.data.gross ?? 0) * 100.0) / 100.0
      )
    },
    // Proxies
    vatRatePercent: {
      get () {
        return Math.floor(this.data.vatRate * 100.0 * 100) / 100
      },
      set (v) {
        this.data.vatRate = v === '' ? null : Math.round(v) / 100.0
      }
    },
    netProxy: {
      get () {
        return this.data.net
      },
      set (v) {
        this.data.net = v === '' ? null : v
      }
    },
    grossProxy: {
      get () {
        return this.data.gross
      },
      set (v) {
        this.data.gross = v === '' ? null : v
      }
    },
    vatProxy: {
      get () {
        return this.data.vat
      },
      set (v) {
        this.data.vat = v === '' ? null : v
      }
    },
    // Proposed values
    proposedVatRate () {
      return this.isProposedVatExempt || this.customer?.countryCode == null
        ? 0
        : countryDefinitions[this.customer.countryCode].vat
    },
    appliedVatRate () {
      return this.data.vatRate ?? this.proposedVatRate
    },
    hasProposedNet () {
      return this.license?.value != null || (this.data.gross && this.data.vatRate)
    },
    proposedNet () {
      if (this.data.gross && this.data.vatRate) {
        const vatFactor = 1 + (this.data.vatRate ?? 0)
        return Math.round((this.data.gross / vatFactor) * 100.0) / 100.0
      }
      return Math.round((this.license?.value ?? 0) * 100.0) / 100.0
    },
    netProposalBasedOn () {
      if (this.data.gross && this.data.vatRate) {
        return 'Brutto-Preis & USt.-Satz'
      } else {
        return 'Lizenz-Preis'
      }
    },
    appliedNet () {
      return this.data.net ?? this.proposedNet
    },
    proposedVat () {
      if (!this.data.net && this.data.gross && this.data.vatRate) {
        const vatFactor = 1 + (this.appliedVatRate ?? 0)
        return Math.round(this.data.gross * (1 - 1 / vatFactor) * 100.0) / 100.0
      }
      return Math.round(this.appliedNet * this.appliedVatRate * 100.0) / 100.0
    },
    vatProposalBasedOn () {
      if (!this.data.net && this.data.gross && this.data.vatRate) {
        return 'Brutto-Preis & USt.-Satz'
      } else {
        return 'Netto-Preis & USt.-Satz'
      }
    },
    appliedVat () {
      return this.data.vat ?? this.proposedVat
    },
    proposedGross () {
      if (this.data.gross && !this.data.net && !this.data.vatRate) {
        return this.data.gross
      }
      return Math.round((this.appliedNet + this.appliedVat) * 100.0) / 100.0
    },
    appliedGross () {
      return this.data.gross ?? this.proposedGross
    },
    applicableProposals () {
      const applicable = []
      if (this.hasProposedNet && this.data.net !== this.proposedNet) {
        applicable.push('net')
      }
      if (this.data.vatRate !== this.proposedVatRate) {
        applicable.push('vatRate')
      }
      if (this.data.vat !== this.proposedVat) {
        applicable.push('vat')
      }
      if (this.data.gross !== this.proposedGross) {
        applicable.push('gross')
      }
      return applicable
    },
    isProposedVatExempt () {
      const REVERSE_CHARGE_COUNTRIES = [
        'AT',
        'BE',
        'BG',
        'CZ',
        'DK',
        'EE',
        'IE',
        'EL',
        'ES',
        'FR',
        'HR',
        'IT',
        'CY',
        'LV',
        'LT',
        'LU',
        'HU',
        'MT',
        'NL',
        'PL',
        'PT',
        'RO',
        'SI',
        'SK',
        'FI',
        'SE'
      ]

      return (
        this.customer?.vatId != null &&
        this.customer?.countryCode != null &&
        REVERSE_CHARGE_COUNTRIES.includes(this.customer.countryCode)
      )
    }
  },
  watch: {
    value () {
      this.data = { ...this.value }
    }
  },
  methods: {
    updateFromNet (net) {
      const vat = Math.round(net * this.proposedVatRate * 100.0) / 100.0
      const gross = Math.round((net + vat) * 100.0) / 100.0
      this.$emit('input', {
        net,
        vatRate: this.proposedVatRate,
        vat,
        gross
      })
    },
    onApplyProposalsButtonClicked () {
      this.$emit('input', {
        net: this.proposedNet,
        vat: this.proposedVat,
        vatRate: this.proposedVatRate,
        gross: this.proposedGross
      })
    },
    clear () {
      this.$emit('input', null)
    },
    applyProposedNet () {
      this.$emit('input', {
        ...this.data,
        net: this.proposedNet
      })
    },
    applyProposedVatRate () {
      this.$emit('input', {
        ...this.data,
        vatRate: this.proposedVatRate
      })
    },
    applyProposedVat () {
      this.$emit('input', {
        ...this.data,
        vat: this.proposedVat
      })
    },
    applyProposedGross () {
      this.$emit('input', {
        ...this.data,
        gross: this.proposedGross
      })
    }
  }
}
</script>
