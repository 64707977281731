<template>
  <div class="license-editor-modal">
    <div class="modal-card" style="margin: 0 auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Lizenz auswählen</p>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <license-editor :license-id="localLicenseId" @input="onLicenseChanged" :editable="true" />
        </div>
        <div class="buttons is-right">
          <b-button @click="$emit('close')">Abbrechen</b-button>
          <b-button type="is-primary" @click="onSubmitButtonClicked" :disabled="!localLicenseId"
            >Lizenz auswählen</b-button
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import LicenseEditor from '@/components/LicenseEditor'
export default {
  components: {
    LicenseEditor
  },
  props: {
    licenseId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      localLicenseId: null
    }
  },
  watch: {
    licenseId () {
      this.updateLocalLicenseId()
    }
  },
  methods: {
    updateLocalLicenseId () {
      this.localLicenseId = this.licenseId
    },
    onLicenseChanged (newLicenseId) {
      this.localLicenseId = newLicenseId
    },
    onSubmitButtonClicked () {
      this.$emit('submit', this.localLicenseId)
      this.$emit('close')
    }
  }
}
</script>
