<template>
  <b-field>
    <b-input :value="value" @input="$emit('input', $event)" placeholder="Suchen" type="search" icon="search"> </b-input>
    <p class="control">
      <span class="button is-outlined">
        <b-tooltip position="is-bottom" multilined>
          <template #content>
            <div class="has-text-left has-text-black">
              <h5 class="mb-2">Durchsuchbare Felder:</h5>

              <p v-for="field in searchFields" :key="field.path" class="mb-1">
                <strong>{{ field.title }}</strong
                ><br />
                {{ field.description }}
              </p>
            </div>
          </template>
          <b-icon icon="question-circle" />
        </b-tooltip>
      </span>
    </p>
  </b-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    searchFields: {
      type: Array
    }
  }
}
</script>
