<template>
  <div class="accounting-view">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="div">
          <div>
            <h1 class="title">Buchhaltung</h1>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="container p-6">
      <div class="has-background-white p-5">
        <b-field label="Buchhaltungs-Export der Stripe-Umsätze">
          <b-datepicker
            type="month"
            placeholder="Monat auswählen..."
            icon="calendar"
            v-model="selectedMonth"
            :date-formatter="dateFormatter"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <p class="buttons">
          <b-button type="is-success" :disabled="!selectedMonth" icon-left="cogs" @click="onCreateReportButtonClicked"
            >XML-Datensatz generieren</b-button
          >
          <b-button
            type="is-info"
            :disabled="!selectedMonth || !xml"
            icon-left="download"
            @click="onDownloadReportButtonClicked"
            >XML-Datensatz herunterladen</b-button
          >
        </p>

        <pre v-if="xml">{{ xml }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { apiMixin } from '@/mixins/api-mixin'
export default {
  mixins: [apiMixin],
  data () {
    return {
      selectedMonth: null,
      xml: null
    }
  },
  methods: {
    dateFormatter (date) {
      return dayjs(date).format('MMMM YYYY')
    },
    async onCreateReportButtonClicked () {
      const { data } = await this.createAccountingExport(this.$store.state.auth.accessToken, this.selectedMonth)
      this.xml = data
    },
    async onDownloadReportButtonClicked () {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(this.xml))
      element.setAttribute(
        'download',
        `SIGNdigital Buchhaltungsexport ${dayjs(this.selectedMonth).format('YYYY-MM-DD')}.xml`
      )

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    }
  }
}
</script>
