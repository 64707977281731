import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from '@/views/AppLayout.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard'

import Categories from '@/views/Categories'
import SignRequests from '@/views/SignRequests'

import Signs from '@/views/Signs'
import SignDetails from '@/views/SignDetails'

import ListTemplates from '@/views/ListTemplates'
import ListTemplateDetails from '@/views/ListTemplateDetails'

import Users from '@/views/Users'
import UserDetails from '@/views/UserDetails'

import Teams from '@/views/Teams'
import TeamDetails from '@/views/TeamDetails'

import Subscriptions from '@/views/Subscriptions'
import SubscriptionDetails from '@/views/SubscriptionDetails'

import Accounting from '@/views/Accounting'
import InvoiceStatements from '@/views/InvoiceStatements'

import { publicPath } from '../../vue.config.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { unauthenticatedAccess: true }
  },
  {
    path: '/app',
    component: AppLayout,
    children: [
      { path: '', redirect: 'dashboard' },
      { path: 'dashboard', name: 'dashboard', component: Dashboard },

      { path: 'signs', name: 'signs', component: Signs },
      { path: 'sign/:id?', name: 'sign-details', component: SignDetails },
      { path: 'categories', name: 'categories', component: Categories },
      { path: 'sign-requests', name: 'sign-requests', component: SignRequests },

      { path: 'list-templates', name: 'list-templates', component: ListTemplates },
      { path: 'list-template/:id?', name: 'list-template-details', component: ListTemplateDetails },

      { path: 'users', name: 'users', component: Users },
      { path: 'user/:id?', name: 'user-details', component: UserDetails },

      { path: 'teams', name: 'teams', component: Teams },
      { path: 'team/:id?', name: 'team-details', component: TeamDetails },

      { path: 'subscriptions', name: 'subscriptions', component: Subscriptions },
      { path: 'subscription/:id?', name: 'subscription-details', component: SubscriptionDetails },

      { path: 'accounting', name: 'accounting', component: Accounting },
      { path: 'invoice-statements', name: 'invoice-statements', component: InvoiceStatements }
    ]
  },
  {
    path: '',
    redirect: '/login'
  }
]
const router = new VueRouter({
  mode: 'history',
  base: publicPath,
  linkExactActiveClass: 'is-active',
  routes
})

export default router
