<template>
  <div id="dashboard-view">
    <div class="container p-6">
      <h1 class="title">Startseite</h1>
      <div class="columns">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <h2 class="subtitle">Gebärden</h2>
                <p>Alle SIGN Gebärden verwalten. Schlüsselwörter, Kategorien, Medien</p>
                <div class="buttons">
                  <b-button tag="router-link" :to="{ name: 'signs' }">Gebärden verwalten</b-button>
                  <b-button tag="router-link" :to="{ name: 'sign-details', query: { action: 'create' } }"
                    >Neue Gebärde anlegen</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <h2 class="subtitle">Lernlisten-Vorlagen</h2>
                <p>Die Lernlisten-Vorlagen, die die Nutzer Vorgeschlagen bekommen</p>
                <div class="buttons">
                  <b-button tag="router-link" :to="{ name: 'list-templates' }">Lernlisten-Vorlagen verwalten</b-button>
                  <b-button
                    tag="router-link"
                    :to="{
                      name: 'list-template-details',
                      query: { action: 'create' }
                    }"
                    >Neue Lernlisten-Vorlage anlegen</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <h2 class="subtitle">Benutzer</h2>
                <p>Nutzer mit Zugang zu SIGNdigital verwalten, Abos einsehen</p>
                <div class="buttons">
                  <b-button tag="router-link" :to="{ name: 'users' }">Benutzer verwalten</b-button>
                  <b-button tag="router-link" :to="{ name: 'user-details', query: { action: 'create' } }"
                    >Neuen Benutzer anlegen</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <subscriptions-card status="pending-approval" title="Bestellungen auf Rechnung, die auf Bestätigung warten" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubscriptionsCard from '@/components/SubscriptionsCard'
export default {
  components: {
    SubscriptionsCard
  }
}
</script>
