<template>
  <div id="signs-view">
    <b-navbar>
      <template #brand>
        <div class="navbar-item">
          <h1 class="title">Gebärden</h1>
        </div>
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <b-input placeholder="Filtern" v-model="query" @input="onQueryInput" />
        </b-navbar-item>
        <b-navbar-item tag="div">
          <!-- Quickfilter -->
          <b-dropdown>
            <template #trigger><b-button icon-left="filter"></b-button></template>
            <b-dropdown-item custom><strong>Schnellfilter:</strong></b-dropdown-item>
            <b-dropdown-item separator />
            <b-dropdown-item :disabled="activeFilter === 'incomplete'" @click="onFilterChanged('incomplete')"
              ><b-icon icon="file-times" class="mr-1" />Unvollständige Gebärden</b-dropdown-item
            >
            <b-dropdown-item :disabled="activeFilter === 'invisible'" @click="onFilterChanged('invisible')"
              ><b-icon icon="eye-slash" class="mr-1" />Unsichtbare Gebärden</b-dropdown-item
            >
            <b-dropdown-item separator />
            <b-dropdown-item @click="onFilterChanged(null)">Filter entfernen</b-dropdown-item>
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <div class="buttons">
            <!-- Create new -->
            <b-button @click="onCreateButtonClicked" type="is-secondary">Neue Gebärde</b-button>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="container p-6">
      <!-- QUICK FILTERS -->
      <section class="filters mb-4" v-if="activeFilter">
        <b-message type="is-info">
          <p><strong>Aktiver Schnellfilter:</strong></p>
          <b-tag v-if="activeFilter === 'incomplete'" closable @close="onFilterChanged(null)"
            ><b-icon icon="file-times" class="mr-1" />Unvollständige Gebärden</b-tag
          >
          <b-tag v-else-if="activeFilter === 'invisible'" closable @close="onFilterChanged(null)"
            ><b-icon icon="eye-slash" class="mr-1" />Unsichtbare Gebärden</b-tag
          >
        </b-message>
      </section>
      <section class="">
        <!-- SIGNS TABLE -->
        <b-table
          class="signs-table p-4"
          striped
          hoverable
          pagination-size="is-small"
          custom-row-key="_id"
          :loading="isFindSignsPending"
          :data="signs"
          :paginated="true"
          pagination-position="both"
          :backend-pagination="true"
          :backend-sorting="true"
          :per-page="limit"
          :current-page="currentPage"
          :total="totalItems"
          :default-sort="[sortField, sortOrder]"
          @page-change="onPageChange"
          @sort="onSort"
          @click="onRowClicked"
        >
          <template #top-left>
            <p>
              Ergebnisse <span class="tag">{{ skip + 1 }}</span> bis
              <span class="tag">{{ Math.min(skip + limit, totalItems) }}</span>
              von <span class="tag">{{ totalItems }}</span>
            </p>
          </template>
          <template #bottom-left>
            <jump-page-control
              :current-page="currentPage"
              :skip="skip"
              :total="totalItems"
              :limit="limit"
              @jump="currentPage = $event"
            />
          </template>
          <b-table-column label="Name" sortable field="name" v-slot="{ row }" @click="onRowClicked(row)">
            {{ row.name }}
            <div class="is-family-monospace is-size-7">{{ row.slug }}</div>
          </b-table-column>

          <b-table-column label="Schlüsselwörter" field="keywordws" v-slot="{ row }">
            <small>{{ row.keywords.join(', ') }}</small>
          </b-table-column>

          <b-table-column label="Medien" v-slot="{ row }">
            <div class="file-statuses">
              <sign-file-status :sign="row" file-type="signBox" @click.native.stop />
              <sign-file-status :sign="row" file-type="signMap" @click.native.stop />
              <sign-file-status :sign="row" file-type="signOnly" @click.native.stop />
              <sign-file-status :sign="row" file-type="signVideo" @click.native.stop />
            </div>
          </b-table-column>

          <b-table-column label="Geändert" sortable field="updatedAt" v-slot="{ row }">
            <small>{{ row.updatedAt | fromNow }}</small>
          </b-table-column>

          <b-table-column label="Sichtbar" sortable field="isPublic" v-slot="{ row }">
            <div class="is-relative">
              <b-loading :is-full-page="false" :active="isPublicStateChanging[row._id]" />
              <div class="is-flex is-justify-content-center is-align-content-center is-align-items-center">
                <b-switch :value="row.isPublic" @input="onIsPublicChanged($event, row)" @click.native.stop> </b-switch>
              </div>
            </div>
          </b-table-column>

          <b-table-column label="Aktionen" v-slot="{ row }">
            <div class="buttons actions">
              <b-button size="is-small" type="" @click.stop="onEditButtonClicked(row)">Bearbeiten</b-button>
              <b-button size="is-small" type="" icon-left="copy" @click.stop="onDuplicateButtonClicked(row)"></b-button>
              <b-button type="is-danger" size="is-small" @click.stop="onDeleteButtonClicked(row)">Löschen</b-button>
            </div>
          </b-table-column>
        </b-table>
      </section>
    </div>
  </div>
</template>

<script>
import SignFileStatus from '@/components/SignFileStatus'
import JumpPageControl from '@/components/JumpPageControl'
import { makeFindMixin } from 'feathers-vuex'

export default {
  mixins: [makeFindMixin({ service: 'signs', watch: true, qid: 'signsOverviewFind' })],
  components: {
    SignFileStatus,
    JumpPageControl
  },
  data () {
    return {
      isPublicStateChanging: {},

      activeFilter: null,
      query: '',
      limit: 20,
      sortField: 'updatedAt',
      sortOrder: 'desc',
      skip: 0
    }
  },
  computed: {
    totalItems () {
      return this.signsPaginationData?.signsOverviewFind?.mostRecent?.total ?? 0
    },
    currentPage: {
      get () {
        return Math.floor(this.skip / this.limit) + 1
      },
      set (newValue) {
        this.skip = (newValue - 1) * this.limit
      }
    },
    signsParams () {
      const params = {
        query: {
          $sort: { [this.sortField]: this.sortOrder === 'asc' ? 1 : -1 },
          $skip: this.skip,
          $limit: this.limit,
          $filter: this.activeFilter
        },
        paginate: true,
        debounce: 500
      }

      if (this.query != null && this.query !== '') {
        params.query.name = { $regex: this.query, $options: 'im' }
      }
      return params
    }
  },
  created () {
    // Parse initial params
    this.parseQuery()
  },
  methods: {
    onFilterChanged (filter) {
      this.activeFilter = filter
      this.updateQuery()
    },
    onPageChange (page) {
      this.skip = (page - 1) * this.limit
      this.updateQuery()
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.updateQuery()
    },
    parseQuery () {
      const params = this.$route.query
      if (params.query) {
        this.query = params.query
      } else {
        this.query = ''
      }

      if (params.sort) {
        if (params.sort.startsWith('-')) {
          // Desc
          this.sortOrder = 'desc'
          this.sortField = params.sort.substring(1)
        } else {
          // Asc
          this.sortOrder = 'asc'
          this.sortField = params.sort
        }
      }

      const parsedSkip = Number.parseInt(params.skip)
      if (!isNaN(parsedSkip)) {
        this.skip = parsedSkip
      }
      if (params.filter) {
        this.activeFilter = params.filter
      }
    },
    updateQuery () {
      console.log('updateQuery')
      this.$router.replace({
        query: {
          query: this.query,
          skip: this.skip,
          sort: (this.sortOrder === 'desc' ? '-' : '') + this.sortField,
          filter: this.activeFilter
        }
      })
    },
    onQueryInput () {
      this.skip = 0
      this.updateQuery()
    },
    async onIsPublicChanged (isPublic, sign) {
      this.$set(this.isPublicStateChanging, sign._id, true)
      try {
        await sign.patch({ data: { isPublic } })
      } catch (error) {
        console.error(error)
      } finally {
        this.$set(this.isPublicStateChanging, sign._id, false)
      }
    },
    onRowClicked (sign) {
      this.$router.push({
        name: 'sign-details',
        params: { id: sign._id },
        query: {}
      })
    },
    onEditButtonClicked (sign) {
      this.$router.push({
        name: 'sign-details',
        params: { id: sign._id },
        query: { action: 'edit' }
      })
    },
    onDetailsButtonClicked (sign) {
      this.$router.push({
        name: 'sign-details',
        params: { id: sign._id },
        query: {}
      })
    },
    onCreateButtonClicked () {
      this.$router.push({
        name: 'sign-details',
        params: {},
        query: { action: 'create' }
      })
    },
    onDuplicateButtonClicked (sign) {
      this.$router.push({
        name: 'sign-details',
        params: {},
        query: { action: 'create', duplicate: sign._id }
      })
    },
    onDeleteButtonClicked (sign) {
      this.$buefy.dialog.confirm({
        title: 'Gebärde wirklich löschen?',
        message: `Soll die Gebärde <code>${sign.name}</code> wirklich <b>endgültig gelöscht</b> werden?`,
        confirmText: 'Löschen',
        cancelText: 'Abbrechen',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await sign.remove()
          this.$buefy.toast.open('Gebärde gelöscht!')
          this.findSigns()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.b-table {
  padding-bottom: 1em;

  table {
    tr {
      cursor: pointer;
    }
    td {
      vertical-align: middle;
    }
  }
}
.file-statuses {
  display: flex;
  align-items: bottom;
}

.buttons.actions {
  flex-wrap: nowrap;
}
</style>
