<template>
  <div id="sign-requests-view">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="div">
          <div>
            <h1 class="title">Gebärden-Wünsche</h1>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="container p-6">
      <b-table
        striped
        hoverable
        v-if="signRequests"
        :data="signRequests"
        default-sort="count"
        default-sort-direction="desc"
      >
        <b-table-column label="Gebärde" sortable field="name" v-slot="{ row }">
          <strong>{{ row.name }}</strong>
        </b-table-column>
        <b-table-column label="Anzahl" sortable field="count" v-slot="{ row }">
          {{ row.count }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import feathersClient from '@/feathers-rest-client'

export default {
  data () {
    return {
      signRequests: null,
      isFindPending: false,
      error: false
    }
  },
  mounted () {
    this.findSignRequests()
  },
  methods: {
    async findSignRequests () {
      this.isFindPending = true
      try {
        this.signRequests = await feathersClient.service('sign-requests').find({ query: { summary: null } })
        this.signRequests.forEach((request) => {
          request.count = request.users.length
        })
        console.log(this.signRequests)
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.isFindPending = false
      }
    }
  }
}
</script>
