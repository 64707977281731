<template>
  <div class="license-editor">
    <!-- Predefined packages -->
    <b-field label="Lizenztyp" />
    <b-field message="Eine vordefinierte Standard-Lizenzgröße und Laufzeit, die auch über Stripe bestellbar ist">
      <b-radio v-model="licenseType" expanded native-value="default" :disabled="!editable">
        <span>Standard</span>
      </b-radio>
    </b-field>

    <!-- License select -->
    <default-license-select
      v-if="licenseType === 'default'"
      :value="localLicense ? localLicense.id : null"
      @input="onDefaultLicenseSelected"
    />

    <b-field message="Eine komplett individuelle Lizenz, die nur auf Rechnung bezahlt werden kann">
      <b-radio v-model="licenseType" expanded native-value="custom" :disabled="!editable">
        <span>Individuell</span>
      </b-radio>
    </b-field>

    <!-- License id -->
    <b-field label="Artikelnummer" v-if="localLicense">
      <span>{{ localLicense.id }}</span>
    </b-field>

    <!-- License size -->
    <b-field label="Lizenz-Größe" expanded v-if="localLicense">
      <b-input
        type="number"
        :disabled="licenseType === 'default' || !editable"
        :value="localLicense.size"
        @input="onCustomLicenseSizeChange"
      />
      <p class="control">
        <span class="button is-static">{{ 'Platz' | pluralizeReplace(localLicense.size, 'Plätze') }}</span>
      </p>
    </b-field>

    <!-- Period end -->
    <b-field label="Abrechnungszeitraum" expanded v-if="localLicense">
      <b-input
        type="number"
        :disabled="licenseType === 'default' || !editable"
        :value="localLicense.period"
        @input="onCustomLicensePeriodChange"
      />
      <p class="control">
        <span class="button is-static">{{ 'Monat' | pluralize(localLicense.period, 'e') }}</span>
      </p>
    </b-field>
  </div>
</template>

<script>
import licenseDefinitions from '@custom-media/signdigital-lib/src/constants/license-definitions'
import { License } from '@custom-media/signdigital-lib/src/license'
import DefaultLicenseSelect from '@/components/DefaultLicenseSelect.vue'
const licenseIds = licenseDefinitions.reduce((ids, lg) => [...ids, ...lg.licenses.map((l) => l.id)], [])
const licenses = licenseIds.map((id) => new License(id))
export default {
  components: {
    DefaultLicenseSelect
  },
  props: {
    licenseId: String,
    editable: Boolean
  },
  data () {
    return {
      licenses,
      licenseType: 'default',
      localLicense: null
    }
  },
  mounted () {
    this.handleLicenseChange()
  },
  watch: {
    licenseId () {
      this.handleLicenseChange()
    },
    licenseType (newValue) {
      if (newValue === 'custom' && this.localLicense === null) {
        this.localLicense = new License('sd-1-1')
      }
    }
  },
  methods: {
    handleLicenseChange () {
      if (this.licenseId && this.localLicense?.id !== this.licenseId) {
        this.localLicense = new License(this.licenseId)
      }
      this.licenseType = this.localLicense?.isCustom ? 'custom' : 'default'
    },
    onDefaultLicenseSelected (licenseId) {
      if (licenseId !== this.localLicense?.id) {
        this.localLicense = new License(licenseId)
        this.$emit('input', licenseId)
      }
    },
    onCustomLicensePeriodChange (period) {
      this.localLicense.isCustom = true
      this.localLicense.period = period
      this.$emit('input', this.localLicense.id)
    },
    onCustomLicenseSizeChange (size) {
      this.localLicense.isCustom = true
      this.localLicense.size = size
      this.$emit('input', this.localLicense.id)
    }
  }
}
</script>
