<template>
  <b-taglist attached>
    <b-tag type="is-secondary">ObjectId</b-tag>
    <b-tag class="is-family-monospace">{{ _id }}</b-tag>
    <b-tag class="p-0"><b-button size="is-small" type="is-ghost" icon-left="copy" @click="copyId"></b-button></b-tag>
    <b-tag class="p-0"
      ><b-button size="is-small" type="is-ghost" icon-left="brackets-curly" @click="copyIdQuery"></b-button
    ></b-tag>
  </b-taglist>
</template>

<script>
export default {
  props: {
    object: {
      type: Object,
      default: null
    },
    oid: {
      type: String,
      default: null
    },
    idField: {
      type: String,
      default: '_id'
    }
  },

  computed: {
    _id () {
      return this.oid ?? this.object?.[this.key ?? '_id'] ?? null
    }
  },
  methods: {
    async copyId () {
      await navigator.clipboard.writeText(this._id)
      this.$buefy.toast.open({
        message: 'ID kopiert',
        type: 'is-success'
      })
    },
    async copyIdQuery () {
      await navigator.clipboard.writeText(`{ ${this.idField}: ObjectId("${this._id}") }`)
      this.$buefy.toast.open({
        message: 'Query kopiert',
        type: 'is-success'
      })
    }
  }
}
</script>
