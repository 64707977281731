<template>
  <div class="list-templates-view">
    <b-navbar>
      <template #brand>
        <div class="navbar-item">
          <h1 class="title">Lernlisten-Vorlagen</h1>
        </div>
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <!-- Create new -->
            <b-button @click="onCreateButtonClicked" type="is-secondary">Neue Lernlisten-Vorlage</b-button>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="container p-6">
      <b-table
        :data="listTemplates"
        :paginated="true"
        :backend-pagination="true"
        :loading="isFindListTemplatesPending"
        :per-page="limit"
        :total="total"
        :current-page="currentPage"
        pagination-position="both"
        pagination-size="is-small"
        @page-change="onPageChange"
        @click="onRowClicked"
      >
        <b-table-column v-slot="{ row: listTemplate }" label="Vorschaubild">
          <list-template-thumbnail :list-template="listTemplate" style="width: 120px" />
        </b-table-column>
        <b-table-column v-slot="{ row: listTemplate }" label="Name">
          <p class="has-text-weight-bold">{{ listTemplate.name }}</p>
          <p class="">{{ listTemplate.description }}</p>
          <b-tag class="mt-2" :type="listTemplate.isPublic ? 'is-success' : 'is-light'"
            ><b-icon class="mr-1" :icon="listTemplate.isPublic ? 'eye' : 'eye-slash'" />{{
              listTemplate.isPublic ? 'Veröffentlicht' : 'Unveröffentlicht'
            }}</b-tag
          >
        </b-table-column>
        <b-table-column label="Aktionen" v-slot="{ row: listTemplate }">
          <div class="buttons are-small">
            <b-button type="" @click.stop="onEditButtonClicked(listTemplate)">Bearbeiten</b-button>
            <b-button type="is-danger" @click.stop="onDeleteButtonClicked(listTemplate)">Löschen</b-button>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import ListTemplateThumbnail from '@custom-media/signdigital-web-shared/src/components/ListTemplateThumbnail.vue'
import { makeFindMixin } from 'feathers-vuex/dist'
import { makeListMixin } from '@/mixins/editor-mixins.js'
const mixinOptions = {
  service: 'list-templates',
  name: 'listTemplates',
  items: 'listTemplates',
  qid: 'listListTemplates',
  readableName: 'Lernlisten-Vorlage',
  readableNameWithArticle: 'die Lernlisten-Vorlage',
  watch: true
}
export default {
  mixins: [makeFindMixin(mixinOptions), makeListMixin(mixinOptions)],
  components: {
    ListTemplateThumbnail
  },
  methods: {}
}
</script>
