<template>
  <div class="list-template-details">
    <validation-observer v-slot="{ invalid }">
      <editor-navbar
        title="Details der Lernlisten-Vorlage"
        create-title="Neue Lernlisten-Vorlage erstellen"
        edit-title="Lernlisten-Vorlage bearbeiten"
        :name="listTemplate.name"
        :is-editing="isEditing"
        :is-creating="isCreating"
        @reset="onResetButtonClicked"
        @edit="onEditButtonClicked"
        @close="onCloseButtonClicked"
        @save="onSaveButtonClicked"
        :invalid="invalid"
      />
      <div class="container p-6">
        <!-- Content -->
        <section class="has-background-white p-5" v-if="listTemplate">
          <div class="columns">
            <div class="column">
              <!-- Thumbnail -->
              <b-field label="Vorschaubild">
                <image-input
                  :image-id="listTemplate.thumbnail"
                  @update:image="onThumbnailChanged"
                  :is-editing="isEditing || isCreating"
                  :allowed-mime-types="['image/png', 'image/jpeg', 'image/bmp', 'image/gif', 'image/svg']"
                  acl="public"
                  :class="'hover-color-' + listTemplate.color"
                />
              </b-field>
            </div>
            <div class="column">
              <!-- Name -->
              <validation-provider name="Name der Lernlisten-Vorlage" rules="required" v-slot="{ errors, valid }">
                <b-field
                  label="Name"
                  :type="{
                    'is-danger': errors.length > 0,
                    'is-primary': valid
                  }"
                  :message="errors[0]"
                >
                  <b-input v-if="isEditing || isCreating" v-model="listTemplate.name" />
                  <span v-else>{{ listTemplate.name }}</span>
                </b-field>
              </validation-provider>
              <!-- Visibility -->
              <b-field label="Sichtbarkeit">
                <b-switch v-if="isEditing || isCreating" v-model="listTemplate.isPublic">{{
                  listTemplate.isPublic ? 'Veröffentlicht' : 'Unsichtbar'
                }}</b-switch>
                <span v-else>{{ listTemplate.isPublic ? 'Veröffentlicht' : 'Unsichtbar' }}</span>
              </b-field>

              <!-- Visibility -->
              <validation-provider name="Hintergrundfarbe" rules="required" v-slot="{ errors, valid }">
                <b-field
                  label="Hintergrundfarbe"
                  :type="{
                    'is-danger': errors.length > 0,
                    'is-primary': valid
                  }"
                  :message="errors[0]"
                >
                  <b-field v-if="isEditing || isCreating">
                    <b-radio-button v-model="listTemplate.color" native-value="primary" type="is-primary">
                      <b-icon icon="square" type="is-primary" />
                      <span>Primärfarbe</span>
                    </b-radio-button>

                    <b-radio-button v-model="listTemplate.color" native-value="secondary" type="is-secondary">
                      <b-icon icon="square" type="is-secondary" />
                      <span>Sekundärfarbe</span>
                    </b-radio-button>
                  </b-field>
                  <span v-else :class="'has-text-' + listTemplate.color">{{
                    listTemplate.color === 'primary' ? 'Primärfarbe' : 'Sekundärfarbe'
                  }}</span>
                </b-field>
              </validation-provider>

              <!-- Description -->
              <b-field label="Beschreibung">
                <b-input v-if="isEditing || isCreating" type="textarea" v-model="listTemplate.description" />
                <span v-else>{{ listTemplate.description }}</span>
              </b-field>
            </div>
          </div>

          <!-- Signs -->
          <div class="level">
            <div class="level-left">
              <label class="has-text-weight-bold">Gebärden</label>
            </div>
            <div class="level-right">
              <b-button @click="onAddSignButtonClicked" :disabled="!isEditable" type="is-secondary" size="is-small"
                >Gebärde hinzufügen</b-button
              >
            </div>
          </div>

          <div class="content">
            <draggable v-model="listTemplate.signs" tag="table" handle=".handle">
              <tr v-for="(sign, index) in listTemplate.signs" :key="sign._id">
                <th style="width: 120px">
                  <image-component class="sign-image" :image="images[index]" />
                </th>
                <th>
                  {{ sign.name }}
                </th>
                <td>
                  <div class="buttons is-right" v-if="isEditing">
                    <b-button icon-left="arrows-v" class="handle" />
                    <b-button type="is-danger" @click="onRemoveSignButtonClicked(sign, index)">Entfernen</b-button>
                  </div>
                </td>
              </tr>
            </draggable>
          </div>
        </section>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import EditorNavbar from '@/components/EditorNavbar'
import { makeEditorMixin } from '@/mixins/editor-mixins.js'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import { SignImageReference } from '@custom-media/signdigital-lib/src/image-reference'
import ImageInput from '@custom-media/signdigital-web-shared/src/components/ImageInput'
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent'
import draggable from 'vuedraggable'

export default {
  mixins: [makeEditorMixin({ service: 'list-templates' })],
  components: {
    ImageInput,
    ImageComponent,
    EditorNavbar,
    draggable
  },
  data () {
    return {}
  },
  computed: {
    images () {
      return this.listTemplate.signs.map((s) => {
        return new ResolvableImage({
          $store: this.$store,
          $resolver: this.$resolver,
          reference: new SignImageReference({
            objectId: s._id ?? s.__id,
            imageType: 'signOnly'
          }),
          variant: 'thumbnail'
        })
      })
    }
  },
  mounted () {},
  methods: {
    onRemoveSignButtonClicked (sign, index) {
      this.listTemplate.signs.splice(index, 1)
    },
    async onAddSignButtonClicked () {
      const image = await this.$pickImage({
        target: event.target,
        propsData: {
          allowedContentTypes: ['signs'],
          allowedSignImageTypes: ['signOnly']
        }
      })
      if (image == null) {
        return
      }
      const { Sign } = this.$FeathersVuex.api
      const sign = await Sign.get(image.objectId)
      if (this.listTemplate.signs == null) {
        this.listTemplate.signs = [sign]
      } else {
        this.listTemplate.signs.push(sign)
      }
    },
    onThumbnailChanged (image) {
      this.listTemplate.thumbnail = image?._id
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.image-input {
  &.hover-color-primary::v-deep {
    .image-component {
      transition: background-color 0.2s linear;
      &:hover {
        background-color: $primary;
      }
    }
  }

  &.hover-color-secondary::v-deep {
    .image-component {
      transition: background-color 0.2s linear;
      &:hover {
        background-color: $secondary;
      }
    }
  }
}

.button.handle:hover {
  cursor: ns-resize;
}
.content table {
  td,
  th {
    vertical-align: middle;
  }
}
</style>
